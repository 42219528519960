import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  sort: null,
  filterText: null,
  filterLabels: null,
};

const channelGridSlice = createSlice({
  name: 'channelGrid',
  initialState,
  reducers: {
    setFilterText: (state, action) => {
      state.filterText = action.payload;
    },
    setFilterLabels: (state, action) => {
      state.filterLabels = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
  },
});

export const {
  setSort,
  setFilterText,
  setFilterLabels,
} = channelGridSlice.actions;

export default channelGridSlice.reducer;
