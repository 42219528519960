import {useContext, useMemo} from "react";
import {useSelector} from "react-redux";
import ChannelContext from "./Contexts/ChannelContext";
import Spinner from "./Spinner";

export default function MessagesPlaceholder({messages, hasLoaded, loading}) {
  const {channelId} = useContext(ChannelContext);
  const {user} = useSelector(state => state.user);
  const channel = useSelector(state => state.channels[channelId] || {});
  const membersMap = useSelector(state => state.channels[channelId]?.members || {});

  const dmToMember = useMemo(() => {
    return Object.values(membersMap).find(member => member.user_id !== user.id);
  }, [membersMap]);

  if (messages.length) return null;

  const isDm = channel.isDm;

  return (
    <>
      {
        isDm && dmToMember ? (
          <div className="bg-gray-500 rounded m-2 p-2 text-sm text-gray-100 text-wrap">
            {
              dmToMember.claimed ? (
                `This is the beginning of your conversation with ${dmToMember.first_name} ${dmToMember.last_name}.`
              ) : (
                `${dmToMember.first_name} isn\'t on Fuse yet. If you send a message request, we’ll invite them to join. Once they sign up, they can respond.`
              )
            }
          </div>
        ) : (
          <div className="mx-auto text-lg text-gray-100 mt-10">
            {
              !hasLoaded && loading && !messages.length ? (
                <div className='flex items-center space-x-2'>
                  <Spinner className='h-5' />
                  <span>Loading...</span>
                </div>
              ) : (
                'Start the conversation! 📢'
              )
            }
          </div>
        )
      }
    </>
  )
}
