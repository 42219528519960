import {createSlice} from "@reduxjs/toolkit";

const initState = {
  totalUnreadMessages: 0,
}

const globalSlice = createSlice({
  name: 'global',
  initialState: initState,
  reducers: {
    setTotalUnreadMessages(state, action) {
      state.totalUnreadMessages = action.payload;
    },
  }
});

export const {
  setTotalUnreadMessages,
} = globalSlice.actions;

export default globalSlice.reducer;
