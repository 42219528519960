import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  spaces: [],
  activeSpace: {id: null, name: null, channels: []},
};

const spacesSlice = createSlice({
  name: 'spaces',
  initialState,
  reducers: {
    setActiveSpace(state, action) {
      state.activeSpace = {
        id: action.payload.id,
        name: action.payload.name,
        channels: [],
      };
    },
    setSpaces(state, action) {
      state.spaces = action.payload;
    },
  }
});

export const {
  setActiveSpace,
  setSpaces,
} = spacesSlice.actions;

export default spacesSlice.reducer;
