import {TypedUseSelectorHook, useSelector} from "react-redux";

type AppState = {
  user: any,
  channels: {
    [key: number | string]: any,
    adhocChannel: { id: number, hasDraftMessage: boolean },
  },
}

const useAppSelector: TypedUseSelectorHook<AppState> = useSelector

export default useAppSelector;
