import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogHeader } from "./ui/Dialog";
import { useSearchParam } from "react-use"
import { Button } from "./ui/Button";
import Logo from './Logo';
import { useLocation } from "wouter";
import Spinner from "./Spinner";
import { DialogFooter } from "./ui/Dialog";

export default function Unsubscribe() {
  const [verifiedToken, setVerifiedToken] = useState(false);
  const [hasUnsubscribed, setHasUnsubscribed] = useState(false);
  const inviteToken = useSearchParam('invite');
  const [, navigate] = useLocation();

  useEffect(() => {
    validateInviteToken();
  }, []);

  function validateInviteToken() {
    fetch('/api/unsubscribe/verify', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ invite: inviteToken }),
    })
    .then(resp => resp.json())
    .then(({success, msg}) => {
      if (success) {
        setVerifiedToken(true);
        return;
      }

      setVerifiedToken(false);
      navigate('/signup');
    })
    .catch(() => {
      setVerifiedToken(false);
      navigate('/signup');
    });
  }

  function unsubscribe() {
    fetch('/api/unsubscribe', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ invite: inviteToken }),
    })
    .then(resp => resp.json())
    .then(({success, msg}) => {
      if (success) {
        setHasUnsubscribed(true);
        return;
      }

      alert(msg);
    });
  }

  if (!verifiedToken) {
    return (
      <div className="mx-auto overflow-y-auto h-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center font-bold space-x-2">
        <Spinner className="w-5 h-5" />
        <span>Verifying link...</span>
      </div>
    )
  }

  return (
    <div className="mx-auto overflow-y-auto h-full">
      <Dialog open={hasUnsubscribed}>
        <DialogContent disableClose className="p-5 bg-gray-800">
          <DialogHeader>
            <h1 className="text-xl font-bold">Your profile is now removed</h1>
          </DialogHeader>
          <p className="my-5">You won't receive further message email notifications from Fuse. You can always create an account if you change your mind.</p>
          <DialogFooter>
            <Button onClick={() => navigate('/signup')}>Return to homepage</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div className="py-10 px-10 lg:px-0 space-y-5 max-w-2xl mx-auto">
        <Logo />
        <h1 className="text-2xl font-bold">Why did I receive this message from Fuse?</h1>
        <p>Fuse is a communication hub for commercial insurance industry professionals. If you received a message from us, it means someone in the industry wanted to reach out to you.</p>
        <p>You can continue the conversation by signing up for a Fuse account. If you decide not to create an account, the sender will understand that you haven't signed up, and you won’t receive further communication from them through Fuse.</p>
        <p>To stop receiving messages from Fuse or other users:</p>
        <p>If you prefer not to be contacted via Fuse, you can remove your information. This will make your profile private, meaning others will no longer be able to find or message you through Fuse. Please note that this does not permanently delete your information from our system, but it will prevent further contact from Fuse and its users.</p>
        <Button onClick={unsubscribe}>Remove my information</Button>
      </div>
    </div>
  )
}
