import {Table as TanTable} from "@tanstack/table-core/build/lib/types";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator
} from "./Dropdown";
import {DropdownMenuTrigger} from "@radix-ui/react-dropdown-menu";
import {Button} from "./Button";
import {AdjustmentsHorizontalIcon} from "@heroicons/react/24/outline";
import * as React from "react";

interface DataTableViewOptionsProps<TData> {
  table: TanTable<TData>
}

export default function DataTableViewOptions<TData>({table}: DataTableViewOptionsProps<TData>) {
  return (
    <div className='flex items-center'>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            size="sm"
            className="ml-auto hidden h-8 lg:flex"
          >
            <AdjustmentsHorizontalIcon className="mr-2 h-4 w-4" />
            View
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[150px]">
          <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {
            table
            .getAllColumns()
            .filter((column) => typeof column.accessorFn !== "undefined" && column.getCanHide())
            .map((column) => {
              if (column.columnDef.meta?.hidden) return null;

              return (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {column.columnDef?.meta?.displayName || column.id}
                </DropdownMenuCheckboxItem>
              )
            })
          }
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
