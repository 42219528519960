import * as React from "react"

import { cn } from "../utils/classes";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "bg-slate-700 flex min-h-[60px] px-1.5 block w-full rounded-md py-0.5 text-white shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 border border-gray-800 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6",
          // "flex min-h-[60px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Textarea.displayName = "Textarea"

export { Textarea }
