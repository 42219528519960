import {configureStore, createListenerMiddleware, getDefaultMiddleware} from '@reduxjs/toolkit';
import rootReducer from "./reducers/rootReducer";

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    return currentState.spaces.activeSpace !== previousState.spaces.activeSpace;
  },
  effect: (action, listenerApi) => {
    // const activeSpace = listenerApi.getState().spaces.activeSpace;
    // console.log('🌽 Listener', {action}, activeSpace);
    // window.localStorage.setItem('activeSpace', JSON.stringify(activeSpace));
  }
});

export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  reducer: rootReducer,
});
