import logoLines from './assets/logo-lines.png';
import FuseLogoText from './FuseLogoText';

export default function Logo() {
  return (
    <div className="flex items-center">
      <img className="h-10" src={logoLines} alt="Logo" />
      <FuseLogoText />
    </div>
  )
}