import {ChatBubbleLeftRightIcon, ChevronDoubleLeftIcon, UsersIcon} from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import {useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "wouter";
import useAppLocation from "../hooks/useAppLocation";
import {classNames} from "../utils/classes";
import DropdownProfileOptions from "./DropdownProfileOptions";

export default function MiniSidebar() {
  const [hidden, setHidden] = useState(false);
  const {user} = useSelector(state => state.user);
  const {totalUnreadMessages} = useSelector(state => state.global);
  const [activeApp] = useAppLocation();

  return (
    <div className='relative'>
      {
        !hidden ? (
          <div className='flex flex-col items-center h-full justify-between'>
            <div className='w-[3rem] grow-0 flex flex-col items-center space-y-3 py-3'>
              <Link to='/chat/all'>
                <Tippy placement='right' content='Conversations'>
                  <div className={classNames(activeApp === 'chat' ? 'bg-gray-500' : '', 'flex items-center justify-center hover:bg-gray-500 rounded transition-colors p-1')}>
                    <ChatBubbleLeftRightIcon className='h-6 w-6'/>
                    {totalUnreadMessages ? <div className='rounded-full px-1.5 py-0 text-center bg-fuse-orange absolute top-1 right-0 inline text-xs'>{totalUnreadMessages}</div> : null}
                  </div>
                </Tippy>
              </Link>
              <Link to='/switchboard/people'>
                <Tippy placement='right' content='People'>
                  <div
                    className={classNames(activeApp === 'switchboard' ? 'bg-gray-500' : '', 'flex items-center justify-center hover:bg-gray-500 rounded transition-colors p-1')}>
                    <UsersIcon className='h-6 w-6'/>
                  </div>
                </Tippy>
              </Link>
            </div>
            <div className='flex flex-col items-center space-y-3 py-3 mb-9'>
            <div className={classNames(activeApp === 'user' ? 'bg-gray-500' : '', 'flex items-center justify-center hover:bg-gray-500 rounded transition-colors p-0.5')}>
                <DropdownProfileOptions contentProps={{side: 'right', align: 'end', alignOffset: -1}}>
                  <img
                    className="h-6 w-6 bg-gray-800 rounded"
                    src={user.avatar_url}
                    alt={`${user.first_name} ${user.last_name} profile picture`}
                  />
                </DropdownProfileOptions>
              </div>
            </div>
          </div>
        ) : null
      }
      <div
        className={classNames(hidden ? '-translate-x-2.5' : '', 'w-50 absolute bottom-2.5 flex justify-center left-2.5 bg-gray-900 p-1 py-2 rounded-r-full transition-transform')}
        onClick={() => setHidden(!hidden)}>
        <ChevronDoubleLeftIcon className={classNames(hidden ? 'rotate-180' : '', 'transition-transform h-4 w-4')}/>
      </div>
    </div>
  )
}
