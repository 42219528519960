import {ChatBubbleLeftRightIcon, Cog6ToothIcon, UsersIcon} from "@heroicons/react/24/outline";
import {useMemo} from "react";
import {Link, useLocation, useRoute} from "wouter";
import DropdownProfileOptions from "../Dashboard/DropdownProfileOptions";
import {classNames} from "../utils/classes";

export default function MobileNav() {
  const [location] = useLocation();

  const isChat = useMemo(() => {
    return location.startsWith('/chat');
  }, [location]);

  return (
    <div className='lg:hidden px-2 py-1 w-full bg-gray-700 grid grid-cols-3 gap-3'>
      <Link to='~/chat/all'>
        <div
          className={classNames(isChat ? 'bg-gray-600' : '', 'flex flex-col items-center justify-center hover:bg-gray-600 rounded p-1 py-1.5')}>
          <ChatBubbleLeftRightIcon className='h-6 w-6'/>
          <div className='text-xs scale-90'>Conversations</div>
        </div>
      </Link>
      <Link to='~/switchboard/people'>
        <div
          className={classNames(!isChat ? 'bg-gray-600' : '', 'flex flex-col items-center justify-center hover:bg-gray-600 rounded p-1 py-1.5')}>
          <UsersIcon className='h-6 w-6'/>
          <div className='text-xs scale-90'>People</div>
        </div>
      </Link>
      <div>
        <DropdownProfileOptions contentProps={{side: 'bottom', align: 'end', alignOffset: 20}}>
          <div className='flex flex-col items-center justify-center hover:bg-gray-600 rounded p-1 py-1.5'>
            <Cog6ToothIcon className='h-6 w-6'/>
            <div className='text-xs scale-90'>Profile</div>
          </div>
        </DropdownProfileOptions>
      </div>
    </div>
  );
}
