import Tippy from "@tippyjs/react";

export default function CompanyAvatar({avatarUrl, cashtag, classes = ''}) {
  if (!avatarUrl) return null;

  return (
    <Tippy content={`$${cashtag}`}>
      <img alt="Company avatar" src={avatarUrl} className={`w-7 h-7 bg-white object-contain rounded-lg ${classes}`} />
    </Tippy>
  );
}
