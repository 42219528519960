import {Link, useParams} from "wouter";
import useSocket from "../hooks/useSocket";
import Spinner from '../Spinner';
import {useEffect, useMemo, useState} from "react";
import {Profile} from "../Switchboard/types";
import {Button} from "../ui/Button";
import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  PaperAirplaneIcon,
  UserCircleIcon,
  UserMinusIcon,
  UserPlusIcon
} from "@heroicons/react/24/outline";
import {toggleContact} from "../utils/fetchers";
import {Alert, AlertDescription, AlertTitle} from "../ui/Alert";
import useCreateDmChannel from "../hooks/useCreateDmChannel";
import useAppSelector from "../hooks/useAppSelector";

export default function UserProfile() {
  const [socket] = useSocket();
  const {user} = useAppSelector(state => state.user);
  const [profile, setProfile] = useState<Profile>();
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [hasLoadedProfile, setHasLoadedProfile] = useState(false);
  const [profilePictureNotAvailable, setProfilePictureNotAvailable] = useState(true);
  const createDmChannel = useCreateDmChannel();
  const {userId: profileId} = useParams<{userId: string}>();

  useEffect(loadProfile, [socket?.connected, profileId]);

  function loadProfile() {
    if (!profileId) return;

    setLoadingProfile(true);

    socket?.emit('profiles:find', {profileId}, ({success, profile}) => {
      setLoadingProfile(false);
      setHasLoadedProfile(true);

      if (!success) return setProfile(undefined);

      setProfile(profile);
    });
  }

  const fullName = useMemo(() => {
    if (!profile) return null;

    return `${profile.firstName} ${profile.lastName}`;
  }, [profile]);

  const companyLocation = useMemo(() => {
    if (!profile) return null;

    return [profile.companyCity, profile.companyState, profile.companyCountry].filter(p => !!p).join(', ');
  }, []);

  function toggle(profile: Profile) {
    toggleContact(profile);
    loadProfile();
  }

  return (
    <div className='space-y-5'>
      {
        loadingProfile && !hasLoadedProfile ? (
          <div className='flex items-center space-x-2 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-50'>
            <Spinner className='h-6' />
            <span>Loading profile...</span>
          </div>
        ) : null
      }
      <Link asChild to='/people'>
        <div className='p-5 flex items-center space-x-3 text-lg font-semibold border-b border-gray-700 pb-4'>
          <ChevronLeftIcon className='h-7' />
          <div>People</div>
        </div>
      </Link>
      {
        profile?.id ? (
          <div className='p-5 pt-1 xl:w-2/3 '>
            <div className='flex items-center justify-between space-x-2'>
              <div className='flex items-center space-x-2'>
                {
                  profilePictureNotAvailable ? (
                    <UserCircleIcon className='h-10' />
                  ) : (
                    <img onError={() => setProfilePictureNotAvailable(true)} src={profile.profilePictureUrl} className='rounded-full ring-2 ring-white w-16 object-cover' alt={`Profile picture for ${fullName}`} />
                  )
                }
                <div className='font-semibold'>{fullName}</div>
              </div>
              <div className='flex items-center space-x-2'>
                <Button className='flex items-center space-x-2' onClick={() => toggle(profile)}>
                  {profile.contact ? <UserMinusIcon className='h-5' /> : <UserPlusIcon className='h-5' />}
                  <span>{profile.contact ? 'Remove from' : 'Add to'} Contacts</span>
                </Button>
                <Button className='flex items-center space-x-2 group' disabled={profile.id === user.id} onClick={() => createDmChannel(profile)}>
                  <PaperAirplaneIcon className='h-5 group-hover:-rotate-45 transition-all' />
                  <span>Message</span>
                </Button>
              </div>
            </div>
            <div className='divide-y divide-gray-500 mt-10'>
              <div className='flex items-center justify-between py-4'>
                <div className='font-bold'>Title</div>
                {profile.title ? <div className='whitespace-pre text-right'>{profile.title}</div> :
                  <div className='italic'>Not listed</div>}
              </div>
              <div className='flex items-center justify-between py-4'>
                <div className='font-bold'>Role</div>
                {profile.role ? <div className='whitespace-pre text-right'>{profile.role}</div> :
                  <div className='italic'>Not listed</div>}
              </div>
              <div className='flex items-center justify-between py-4'>
                <div className='font-bold'>Segment</div>
                {profile.insuranceSegment ? <div>{profile.insuranceSegment}</div> :
                  <div className='italic'>Not listed</div>}
              </div>
              <div className='flex items-center justify-between py-4'>
                <div className='font-bold'>Industry</div>
                {profile.industry ? <div>{profile.industry}</div> : <div className='italic'>Not listed</div>}
              </div>
              <div className='flex items-center justify-between py-4'>
                <div className='font-bold'>Industry Type</div>
                {profile.industryType ? <div>{profile.industryType}</div> : <div className='italic'>Not listed</div>}
              </div>
              <div className='flex items-center justify-between py-4'>
                <div className='font-bold'>Company</div>
                {profile.companyName ? <div>{profile.companyName}</div> : <div className='italic'>Not listed</div>}
              </div>
              <div className='flex items-center justify-between py-4'>
                <div className='font-bold'>Company Location</div>
                {companyLocation ? <div>{companyLocation}</div> : <div className='italic'>Not listed</div>}
              </div>
              <div className='flex items-center justify-between py-4'>
                <div className='font-bold'>Position</div>
                {profile.position ? <div>{profile.position}</div> : <div className='italic'>Not listed</div>}
              </div>
              <div className='flex items-center justify-between py-4'>
                <div className='font-bold'>Position Description</div>
                {profile.positionDescription ?
                  <div className='whitespace-pre text-right'>{profile.positionDescription}</div> :
                  <div className='italic'>Not listed</div>}
              </div>
              <div className='py-4'>
                <div className='font-bold'>Bio</div>
                {profile.bio ? <div className='mt-2'>{profile.bio}</div> : <div className='italic'>Not listed</div>}
              </div>
            </div>
          </div>
        ) : (
          <>
            {
              !loadingProfile ? (
                <div className='mx-10 min-w-[400px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                  <Alert>
                    <AlertTitle>Not Found</AlertTitle>
                    <AlertDescription>This profile could not be found.</AlertDescription>
                    <Link to='~/switchboard/people'>
                      <div className='flex space-x-2 items-center mt-4'>
                        <ArrowLeftIcon className='h-4 w-4' />
                        <span>Search for profiles</span>
                      </div>
                    </Link>
                  </Alert>
                </div>
              ) : null
            }
          </>
        )
      }
    </div>
  )
}
