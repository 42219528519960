import * as React from "react"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import {cn} from '../utils/classes';
import {CheckIcon} from "@heroicons/react/20/solid";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(`
      peer
      block
      h-4
      w-4
      shrink-0
      rounded-sm
      bg-gray-500
      ring-gray-50
      border border-fuse-orange
      focus-visible:outline-none
      focus-visible:ring-2
      focus-visible:ring-ring
      focus-visible:ring-offset-2
      disabled:cursor-not-allowed
      disabled:opacity-50
      data-[state=checked]:text-white
      `,
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-current")}
    >
      <CheckIcon className="h-4 w-4" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
