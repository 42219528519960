import {useEffect} from "react";
import {useInterval} from "react-use";
import useSocket from "./useSocket";

export default function useOnlineBeacon() {
  const [socket] = useSocket();
  useInterval(notifyIsOnline, 20000);
  useEffect(() => {
    if(socket) notifyIsOnline();
  }, [socket]);

  function notifyIsOnline() {
    socket.emit('user:online');
  }
}
