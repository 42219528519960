import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/outline";
import parsePhoneNumberFromString from "libphonenumber-js";
import {useEffect, useState} from "react";
import Spinner from "../Spinner";
import {Input} from "./Input";

export default function PhoneNumberInput({placeholder, onChange, defaultValue}) {
  const [phoneNumber, setPhoneNumber] = useState(parsePhoneNumberFromString(defaultValue, 'US')?.formatNational());
  const [verifyingPhoneNumber, setVerifyingPhoneNumber] = useState(false);
  const [verificationError, setVerificationError] = useState();

  useEffect(() => {
    setAndVerifyNumber(phoneNumber);
  }, [phoneNumber]);

  function setAndVerifyNumber(value) {
    if (!value) return;
    setVerifyingPhoneNumber(true);

    fetch('/api/profiles/verify_phone', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({phone: value}),
    })
    .then(resp => resp.json())
    .then(({success, msg}) => {
      if (!success) return setVerificationError(msg);
      setVerificationError();

      onChange(success, value);
    })
    .finally(() => {
      setVerifyingPhoneNumber(false);
    })
  }

  function handleInputChange({target: {value: input}}) {
    const parsedNumber = parsePhoneNumberFromString(input, 'US'); // Replace 'US' with the default country code

    if (parsedNumber) {
      setPhoneNumber(parsedNumber?.formatNational());
    } else {
      setPhoneNumber(input);
    }
  }

  return (
    <div>
      <div className='relative'>
        <div className='absolute right-2 top-2.5 scale-125'>
          {
            verifyingPhoneNumber ? (
              <Spinner className='h-5'/>
            ) : (
              <>
                {phoneNumber && !verificationError ? <CheckCircleIcon className='text-green-500 h-5'/> : null}
                {phoneNumber && verificationError ? <XCircleIcon className='text-red-500 h-5'/> : null}
              </>
            )
          }
        </div>
        <Input
          type="tel"
          className='py-2 text-md'
          value={phoneNumber}
          onChange={handleInputChange}
          placeholder={placeholder}
        />
      </div>
      {verificationError ? <div className='text-sm text-red-400 text-right'>{verificationError}</div> : null}
    </div>
  );
}
