import {ColumnDef} from "@tanstack/react-table";
import {Profile} from "./types";
import {sortableHeader} from "./utils";
import useAppSelector from "../hooks/useAppSelector";
import useSocket from "../hooks/useSocket";
import {useEffect, useState} from "react";
import {toast, ToastOptions} from "react-toastify";
import {DEFAULT_TOAST_CONFIG} from '../utils/toast';
import {Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle} from "../ui/Dialog";
import {Badge} from "../ui/Badge";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../ui/Table";
import {Button} from "../ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem, DropdownMenuLabel,
  DropdownMenuSeparator
} from "../ui/Dropdown";
import {
  BugAntIcon,
  CheckBadgeIcon,
  ChevronDownIcon, EyeIcon, EyeSlashIcon,
  HandRaisedIcon,
  PaperAirplaneIcon,
  QuestionMarkCircleIcon,
  UserMinusIcon,
  UserPlusIcon
} from "@heroicons/react/24/outline";
import {DropdownMenuTrigger} from "@radix-ui/react-dropdown-menu";
import * as React from "react";
import useCreateDmChannel from "../hooks/useCreateDmChannel";
import {Separator} from "../ui/Separator";

function ManageClaimRequest({
  profileId,
  requestingUserId,
  open,
  onOpenChange,
}: {
  profileId: string,
  requestingUserId: string,
  open: boolean,
  onOpenChange: (value: boolean) => void
}) {
  const [socket] = useSocket();
  const [profile, setProfile] = useState<Profile>();
  const [requestingUser, setRequestingUser] = useState<Profile>();

  useEffect(() => {
    if (!open || !socket?.connected || !requestingUserId) return;

    socket.emit('profiles:find', {profileId}, ({success, profile}) => {
      if (success) setProfile(profile);
    });

    socket.emit('profiles:find', {profileId: requestingUserId}, ({success, profile}) => {
      if (success) setRequestingUser(profile);
    });
  }, [open, requestingUserId, socket]);

  function handleClaimRequest(approved: boolean) {
    if (approved && !window.confirm('Are you sure you want to approve? This action CANNOT be undone.')) {
      return;
    }

    socket.emit('profiles:claim:set', {approved, profileId: profile.id}, ({success, msg}) => {
      onOpenChange(false);

      if (success) {
        toast.success(msg, DEFAULT_TOAST_CONFIG as ToastOptions);
        return;
      }

      toast.error(msg, DEFAULT_TOAST_CONFIG as ToastOptions);
    });
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {
        requestingUser && profile ? (
          <DialogContent className='p-6 pt-5 sm:max-w-[625px]'>
            <DialogHeader>
              <DialogTitle>Approval Request</DialogTitle>
              <DialogDescription>
                Approving this request will connect the user and profile together
              </DialogDescription>
            </DialogHeader>
            <span>
              {profile.claimed ? <Badge>Claimed</Badge> : null}
            </span>
            <div>
              <span className='italic'>{ profile.user?.firstName } {profile.user?.lastName}</span>
              {' '}has requested to claim this profile
            </div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[100px]"></TableHead>
                  <TableHead>Requesting User</TableHead>
                  <TableHead>Profile</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell className="font-medium">ID</TableCell>
                  <TableCell>{requestingUser.id.substring(0, 7)}</TableCell>
                  <TableCell>{profile.id.substring(0, 7)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium">Name</TableCell>
                  <TableCell>{requestingUser.firstName} {requestingUser.lastName}</TableCell>
                  <TableCell>{profile.firstName} {profile.lastName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium">Company</TableCell>
                  <TableCell>{requestingUser.company?.name || 'Unknown'}</TableCell>
                  <TableCell>{profile.companyName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium">Email</TableCell>
                  <TableCell>{requestingUser.email || 'Unknown'}</TableCell>
                  <TableCell>{profile.email || 'Unknown'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <DialogFooter>
              <Button size='sm' variant='ghost' onClick={() => handleClaimRequest(false)}>Decline</Button>
              <Button size='sm' onClick={() => handleClaimRequest(true)}>Approve</Button>
            </DialogFooter>
          </DialogContent>
        ) : null
      }
    </Dialog>
  )
}

function ActionsCell({row, table}) {
  const [socket] = useSocket();
  const {user} = useAppSelector(state => state.user);
  const [showApproveClaimDialog, setShowApproveClaimDialog] = useState<boolean>(false);
  const createDmChannel = useCreateDmChannel();
  const profile = row.original as unknown as Profile;

  function claimProfile() {
    socket.emit('profiles:claim', {profileId: profile.id}, ({success, msg}) => {
      if (!success) {
        toast.error(msg, DEFAULT_TOAST_CONFIG as ToastOptions);
        return;
      }

      table.options.meta.reload();

      toast.success(msg, DEFAULT_TOAST_CONFIG as ToastOptions);
    });
  }

  function togglePublic(profileId: string, isPublic: boolean) {
    const confirmationMessage = isPublic ? 'Are you sure you want to reactivate this profile?' : 'Are you sure you want to delete this profile?';

    if (!window.confirm(confirmationMessage)) return;

    socket.emit('profiles:public:toggle', {profileId, isPublic: !isPublic}, ({success, msg}) => {
      if (!success) {
        toast.error(msg, DEFAULT_TOAST_CONFIG as ToastOptions);
        return;
      }

      toast.success(msg, DEFAULT_TOAST_CONFIG as ToastOptions);
      table.options.meta.reload();
    });
  }

  return (
    <>
      <ManageClaimRequest profileId={profile.id} requestingUserId={profile.userProfileId} open={showApproveClaimDialog} onOpenChange={(open) => {
        setShowApproveClaimDialog(open);
        table.options.meta.reload();
      }} />
      <div className='flex items-center justify-end' onClick={(event) => {
        event.stopPropagation();
      }}>
        <DropdownMenu>
          <div className='[&>button]:rounded-none [&>button]:shadow-none divide-x rounded-lg flex overflow-hidden scale-90'>
            <Button className='space-x-2 group' onClick={() => {
              createDmChannel(profile);
            }} disabled={profile.id === user.id}>
              <div className='flex items-center space-x-2'>
                <PaperAirplaneIcon className='h-5 group-hover:-rotate-45 transition' />
                <span className='hidden sm:inline'>Message</span>
              </div>
            </Button>
            <Separator orientation='vertical' />
            <DropdownMenuTrigger asChild>
              <Button>
                <ChevronDownIcon className='h-5 w-5'/>
              </Button>
            </DropdownMenuTrigger>
          </div>
          <DropdownMenuContent align="end">
            <DropdownMenuGroup>
              <DropdownMenuItem onClick={() => {
                const operation = profile.contact ? 'remove' : 'add'

                socket.emit(`profiles:contact:${operation}`, {
                  contactId: profile.id,
                }, ({success, msg}) => {
                  const toaster = success ? toast.success : toast.error;

                  toaster(msg, DEFAULT_TOAST_CONFIG);
                  table.options.meta.reload();
                });
              }}>
                {profile.contact ? <UserMinusIcon className='h-4 w-4 mr-2' /> : <UserPlusIcon className='h-4 w-4 mr-2' />}
                {profile.contact ? 'Remove' : 'Add'} Contact
              </DropdownMenuItem>
              <DropdownMenuItem disabled={!!user.user_profile_id || profile.claimed || !!profile.userProfileId} onClick={() => claimProfile()}>
                <HandRaisedIcon className='h-4 w-4 mr-2' />
                Claim Profile
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuGroup>
              {
                user.role_type === 'admin' ? (
                  <>
                    <DropdownMenuSeparator />
                    <DropdownMenuLabel>Admin</DropdownMenuLabel>
                    <DropdownMenuItem onClick={() => console.log({profile, user})}>
                      <BugAntIcon className='h-4 w-4 mr-2' />
                      Debug
                    </DropdownMenuItem>
                    <DropdownMenuItem disabled={!profile.userProfileId || profile.claimed} onClick={() => setShowApproveClaimDialog(true)}>
                      <CheckBadgeIcon className='h-4 w-4 mr-2' />
                      Manage Claim
                    </DropdownMenuItem>
                    <DropdownMenuItem disabled={false} onClick={() => togglePublic(profile.id, profile.isPublic)}>
                      {
                        profile.isPublic ? (
                          <>
                            <EyeSlashIcon className='h-4 w-4 mr-2' />
                            Set to Private
                          </>
                        ) : (
                          <>
                            <EyeIcon className='h-4 w-4 mr-2' />
                            Set to Public
                          </>
                        )
                      }
                    </DropdownMenuItem>
                  </>
                ) : null
              }
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  );
}

export default function getColumns(user): ColumnDef<Profile>[] {
  return [
    {
      accessorKey: "claimed",
      maxSize: 25,
      header: sortableHeader('Claimed'),
      cell: ({getValue}) => {
        return (<div>{getValue() ? 'Claimed' : 'Unclaimed'}</div>)
      },
      meta: {
        displayName: 'Claimed',
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue('claimed'));
      },
    },
    {
      header: sortableHeader('Name'),
      accessorKey: 'fullName',
      size: 300,
      cell: ({row}) => {
        const [noImgFound, setNoImgFound] = useState(false);
        const {profilePictureUrl: url, firstName, lastName} = row.original;
        const profileFullName = [firstName, lastName].filter(n => !!n);

        return (
          <div className='flex items-center space-x-2'>
            {
              noImgFound || !url ? (
                <div className='flex items-center justify-center'>
                  <QuestionMarkCircleIcon className='h-7 w-7 text-gray-300' />
                </div>
              ) : (
                <img
                  onError={() => setNoImgFound(true)}
                  className='object-cover h-8 w-8 rounded'
                  src={url}
                />
              )
            }
            <div>{profileFullName.join(' ')}</div>
          </div>
        );
      },
      meta: {
        displayName: 'Name',
      },
    },
    {
      header: sortableHeader("Company"),
      accessorKey: "companyName",
      size: 300,
      meta: {
        displayName: 'Company',
      },
    },
    {
      accessorKey: "role",
      header: sortableHeader("Role"),
    },
    {
      header: sortableHeader("Position"),
      accessorKey: "position",
    },
    {
      accessorKey: "industry",
      header: sortableHeader("Industry"),
      filterFn: (row, id, value) => value.includes(row.getValue(id)),
    },
    {
      accessorKey: "industryType",
      header: sortableHeader("Type"),
      meta: {
        displayName: 'Industry Type',
      },
    },
    {
      accessorKey: "insuranceSegment",
      header: sortableHeader("Segment"),
      meta: {
        displayName: 'Segment',
      },
    },
    {
      header: sortableHeader('Co Location'),
      accessorKey: 'coLocation',
      cell: ({row}) => {
        const location = [row.original.companyCity, row.original.companyState, row.original.companyCountry].filter(n => !!n);
        return (<div>{location.join(', ')}</div>);
      },
      meta: {
        displayName: 'Name',
      },
    },
    {
      header: sortableHeader("Company City"),
      accessorKey: "companyCity",
      meta: {
        hidden: true
      }
    },
    {
      header: sortableHeader("Company State"),
      accessorKey: "companyState",
      meta: {
        hidden: true
      }
    },
    {
      header: sortableHeader("Company Country"),
      accessorKey: "companyCountry",
      size: 150,
      meta: {
        hidden: true
      }
    },
    {
      header: sortableHeader("LOB"),
      accessorKey: "lobSpecialty",
      meta: {
        displayName: 'LOB',
      },
    },
    {
      header: sortableHeader("Market"),
      accessorKey: "market",
      meta: {
        displayName: 'Market',
      },
    },
    {
      header: sortableHeader("Structure"),
      accessorKey: "structure",
      meta: {
        displayName: 'Structure',
      },
    },
    {
      accessorKey: 'isPublic',
      maxSize: 25,
      header: sortableHeader('Public'),
      cell: ({getValue}) => {
        return (<div>{getValue() ? 'Public' : 'Private'}</div>)
      },
      meta: {
        displayName: 'Public',
        adminOnly: true,
        hidden: user.role_type !== 'admin',
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue('is_public'));
      },
    },
    {
      id: "actions",
      size: 50,
      cell: ActionsCell,
    }
  ];
};
