import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {useCopyToClipboard, useLocalStorage} from "react-use";
import {setAdhocChannel} from "./reducers/channelsSlice";
import {notify} from "./utils/Notify";
import {DEFAULT_TOAST_CONFIG} from "./utils/toast";

export default function SocketDebugger() {
  const me = useSelector((state) => state.user);
  const [, copyToClipboard] = useCopyToClipboard();
  const [socketDebug] = useLocalStorage('socketDebug', false);
  const dispatch = useDispatch();

  if (!socketDebug) return null;

  return (
    <div className="w-full text-white top-0 left-0 fixed text-xs z-[99999] pointer-events-none">
      <div className="bg-black w-96 mx-auto text-center pointer-events-auto p-1 rounded-b-md">
        <div>Server: {me?.pmId} @ {me?.server}</div>
        <div>Socket: {me?.socketId}</div>
        <div onClick={() => copyToClipboard(me?.user?.id)}>User: {me?.user?.id}</div>
        <div className="flex justify-between items-center">
          <div onClick={() => {
            methodThatDoesNotExist();
          }}>Create FE Error
          </div>
          <div onClick={() => {
            socket.emit('debug:create_error', {err: 'My manual BE error'});
          }}>Create BE Error
          </div>
          <div onClick={() => notify({title: 'Test Notification', body: 'test test test', force: true}, () => {
            console.log('clicked notification');
            dispatch(setAdhocChannel(10));
          })}>
            Notify
          </div>
          <div onClick={() => toast.success('Some content', {...DEFAULT_TOAST_CONFIG, autoClose: undefined})}>
            Toast
          </div>
        </div>
      </div>
    </div>
  );
}
