import {useMemo} from "react";
import {useSelector} from "react-redux";
import {useRoute} from "wouter";

export default function useAppLocation() {
  const {spaces} = useSelector(state => state.spaces);
  const {user} = useSelector(state => state.user);
  const [, {0: route}] = useRoute();
  const [app, ...appParams] = route.split('/');

  const [activeSpace, activeLabel] = useMemo(() => {
    if (app !== 'chat') return [];

    const [activeSpaceId] = appParams;
    const activeSpace = spaces.find(s => s.id === activeSpaceId);
    const activeLabel = user?.labels?.find(l => l.uuid === activeSpaceId);

    return [activeSpace || activeLabel, !!activeLabel];
  }, [spaces, route, app, appParams]);

  return [app, {activeSpace, activeLabel}];
}
