import { LexicalComposer } from "@lexical/react/LexicalComposer";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import {$generateHtmlFromNodes, $generateNodesFromDOM} from '@lexical/html';
import {$getRoot, $insertNodes} from "lexical";
import {useDispatch, useSelector} from "react-redux";
import useChatMembers from "../hooks/useChatMembers";
import {setAdhocChannelHasDraftMessage} from "../reducers/channelsSlice.js";
import AutoLinkPlugin from './AutoLinkPlugin';
import ToolbarPlugin from "./ToolbarPlugin";
import {BeautifulMentionsPlugin, BeautifulMentionNode} from "lexical-beautiful-mentions";
import {useMemo, forwardRef, useContext, useEffect} from "react";
import {classNames} from '../utils/classes';
import theme from "./theme.js";
import ChannelContext from "../Contexts/ChannelContext.jsx";
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin.js";
import throttle from "lodash.throttle";
import useSocket from "../hooks/useSocket.js";
import './RichTextEditor.css';

function Placeholder() {
  return <div className="editor-placeholder p-0 text-gray-500 overflow-hidden text-sm absolute truncate top-1 left-1 select-none inline-block pointer-events-none">Type your message...</div>;
}

const editorConfig = {
  // The editor theme
  theme: {
    ...theme,
    beautifulMentions: {
      "@": "",
      "@Focused": "outline-none shadow-md",
      "$": "",
      "$Focused": "outline-none shadow-md",
    }
  },
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    AutoLinkNode,
    LinkNode,
    BeautifulMentionNode,
  ]
};

function InitialTextLoader({text}) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (!text || !editor) return;

    editor.update(() => {
      // In the browser you can use the native DOMParser API to parse the HTML string.
      const parser = new DOMParser();
      const dom = parser.parseFromString(text, 'text/html');

      // Once you have the DOM instance it's easy to generate LexicalNodes.
      const nodes = $generateNodesFromDOM(editor, dom);

      // Select the root
      $getRoot().select();

      // Insert them at a selection.
      $insertNodes(nodes);
    });
  }, [editor, text]);
}

export default function Editor({children, text, disableTyping = false}) {
  const {channelId} = useContext(ChannelContext);
  const membersMap = useSelector(state => state.memberStatuses);
  const channelMembers = useChatMembers(channelId);
  const [socket] = useSocket();
  const dispatch = useDispatch();

  const typing = useMemo(() => {
    if (!socket || disableTyping) return () => {};

    let timeout;

    return throttle((editorState, editor) => {
      editorState.read(() => {
        const html = $generateHtmlFromNodes(editor, null);

        const el = document.createElement('div');
        el.innerHTML = html;
        const plainText = el.innerText;

        dispatch(setAdhocChannelHasDraftMessage(!!plainText.length));
      });

      clearTimeout(timeout);

      timeout = setTimeout(() => socket.emit(`typing`, {channel_id: channelId, typing: false}), 4000);

      socket.emit(`typing`, {channel_id: channelId, typing: true});
    }, 2000);
  }, [socket]);

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <InitialTextLoader text={text} />
      <div className="border-2 border-gray-400 rounded-sm py-0.5">
        <div className="p-0.5">
          <ToolbarPlugin />
          <div className="editor-inner overflow-y-auto block max-h-[90px] bg-transparent text-white outline-none ring-0 ring-inset focus:ring-opacity-100 min-h-10 ring-opacity-50 ring-gray-300 focus:ring-white w-full overflow-hidden leading-6">
            <RichTextPlugin
              contentEditable={<ContentEditable className="relative p-0.5 pt-1 pb-[6px] text-sm outline-none bg-gray-800 text-white" />}
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <ClearEditorPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <OnChangePlugin onChange={typing} />
            <BeautifulMentionsPlugin
              triggers={['@', '\\$']}
              onSearch={async (trigger, query) => {
                if (trigger === '@') {
                  return Promise.resolve(channelMembers.map((props) => ({
                    ...props,
                    id: props.user_id,
                    value: `${props.first_name} ${props.last_name}`
                  })));
                }

                const results = await socket.emitWithAck('companies:search', {query});

                return results.map((company) => ({...company, isCompany: true, label: `${company.name} [$${company.cashtag}]`, value: company.cashtag}));
              }}
              creatable={false}
              showMentionsOnDelete
              menuAnchorClassName="z-[9999]"
              menuComponent={forwardRef((props, ref) => {
                const {open, loading, anchorElementRef, ...other} = props;

                if (loading) {
                  return (
                    <div
                      ref={ref}
                      className="z-50 mt-6 whitespace-nowrap rounded-lg bg-white p-2.5 text-slate-950 shadow-lg shadow-gray-900 dark:bg-gray-900 dark:text-slate-300"
                    >
                      Loading...
                    </div>
                  )
                }

                return (
                  <ul
                    ref={ref}
                    style={{
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                    className="z-50 m-0 mt-6 list-none overflow-scroll overflow-y-scroll rounded-lg p-0 shadow-lg shadow-gray-900 bg-gray-900"
                    {...other}
                  />
                )
              })}
              menuItemComponent={forwardRef(({ selected, itemValue, label, isCompany = false, avatar_url, id, status, ...props }, ref) => {
                if (!id) return null;

                return (
                  <li
                    ref={ref}
                    className={classNames(
                      "m-0 flex min-w-[250px] shrink-0 cursor-pointer flex-row content-center whitespace-nowrap border-0 px-2.5 py-2 leading-4 outline-none first:mt-1.5 last:mb-1.5 text-white",
                      selected ? "bg-gray-700" : "bg-gray-900",
                    )}
                    {...props}
                  >
                    <div className="flex justify-between items-center w-full">
                      <div className="flex items-center space-x-2 text-sm">
                        <img loading='lazy' className="rounded-full w-5" src={avatar_url} />
                        <span>{label}</span>
                      </div>
                      {
                        !isCompany ? (
                          <div className={classNames('rounded-full w-2.5 h-2.5', membersMap[id]?.status === 'ONLINE' ? 'bg-green-500' : 'bg-gray-400')} />
                        ) : null
                      }
                    </div>
                  </li>
                )
              })}
            />
          </div>
        </div>
        {children}
      </div>
    </LexicalComposer>
  );
}
