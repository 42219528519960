import {useContext} from 'react';
import {useSelector} from "react-redux";
import {useDebounce} from "react-use";
import ChannelContext from '../Contexts/ChannelContext';
import {ChatBubbleLeftEllipsisIcon} from "@heroicons/react/24/outline/index.js";
import {setIsTyping} from "../reducers/channelsSlice";

export default function TypingIndicator() {
  const {channelId} = useContext(ChannelContext);
  const typing = useSelector(state => state.channels[channelId].typing);

  useDebounce(
    () => {
      setIsTyping(null);
    },
    2000,
    [typing]
  );

  return typing ? (
    <div className="text-xs py-2 flex items-center space-x-1 truncate">
      <ChatBubbleLeftEllipsisIcon className="flex-none h-4 w-4" />
      <span className="grow">{typing}...</span>
    </div>
  ) : null;
}
