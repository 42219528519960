import {useMemo} from "react";
import {useSelector} from 'react-redux';

export function useActiveChannelMembersSelector(channelId) {
  const channelMembers = useSelector(state => Object.values(state.channels[channelId]?.members || []));
  return channelMembers.filter(member => !member.removed_from_channel_at).map(({user_id}) => user_id);
}

export function useDmUser(channelId) {
  const {user} = useSelector(state => state.user);
  const members = useSelector(state => state.channels[channelId]?.members);

  return useMemo(() => {
    return Object.values(members).find(member => member.user_id !== user.id);
  }, [members]);
}
