import {useMemo} from "react";
import {useSelector} from "react-redux";

export default function useChatMembers(channelId) {
  const {user} = useSelector(state => state.user);
  const membersMap = useSelector(state => state.memberStatuses);
  const channelsById = useSelector(state => state.channels);

  return useMemo(() => {
    if (!channelId) return [];

    const channel = channelsById[channelId];
    const userIds = channel.users;

    return userIds.map((userId) => {
      if (userId === user.id) return;
      return membersMap[userId];
    }).filter(user => !!user);
  }, [channelId, membersMap, user, channelsById]);
}
