import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  user: {
    hasLoaded: false,
    avatar_url: null,
    email: null,
    id: null,
    status: null,
    status_updated_at: null,
    username: null,
    labels: [],
  },
  socketId: null,
  server: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      return {
        ...action.payload,
        user: {
          hasLoaded: true,
          ...action.payload.user
        }
      };
    }
  }
});

export const {
  setUser,
} = userSlice.actions;

export default userSlice.reducer;
