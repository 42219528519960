import {useEffect, useRef, useState} from "react";
import {useSearchParam} from "react-use";
import {useLocation} from "wouter";
import FuseLogoText from "./FuseLogoText";
import Spinner from "./Spinner";
import logoLines from './assets/logo-lines.png';
import {Button} from "./ui/Button";
import {classNames} from "./utils/classes";

export default function Login() {
  const usernameInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const [loggingIn, setLoggingIn] = useState(false);
  const [flashMessage, setFlashMessage] = useState(false);
  const [flashType, setFlashType] = useState(null);
  const [allowConfirmationResend, setAllowConfirmationResend] = useState(false);
  const confirmationToken = useSearchParam('token');
  const [confirmingToken, setConfirmingToken] = useState(!!confirmationToken);
  const [, navigate] = useLocation();

  useEffect(() => {
    if (!confirmationToken) return;

    fetch(`/confirm/${confirmationToken}`, {
      method: 'PATCH',
    })
    .then((resp) => resp.json())
    .then((resp) => {
      setTimeout(() => {
        setConfirmingToken(false);
        setFlashType(resp.success ? 'success' : 'error');
        setFlashMessage(resp.msg);
        setAllowConfirmationResend(resp.allow_confirmation_resend);
      }, 3000);
    });
  }, [confirmationToken]);

  function resendConfirmationEmail() {
    fetch(`/confirm/${allowConfirmationResend}/resend`, {
      method: 'PATCH',
      body: JSON.stringify({resend: true}),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((resp) => resp.json())
    .then(({success, msg}) => {
      setFlashType(success ? 'success' : 'error');
      setFlashMessage(msg);
      setAllowConfirmationResend(null);
    });
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      {
        confirmingToken ? (
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="flex items-center justify-center space-x-5">
              <Spinner className="h-10 w-10" />
              <div className="text-xl font-semibold">Confirming your account...</div>
            </div>
          </div>
        ) : (
          <div className="-mt-20">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm mb-5">
              <div className="flex items-center justify-center space-x-2">
                <img
                  className="h-12 w-auto"
                  src={logoLines}
                  alt="Your Company"
                />
                <FuseLogoText />
              </div>
              <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-white">
                Sign in
              </h2>
            </div>

            {
              flashMessage ? (
                <div
                  className={classNames(
                    flashType === 'error' ? 'border-red-500 bg-red-500/50' : '',
                    flashType === 'success' ? 'border-green-500 bg-green-500/50' : '',
                    'mx-auto max-w-lg font-semibold border-2 p-3 text-white rounded-lg text-center'
                  )}
                >
                  {flashMessage}
                  {
                    allowConfirmationResend ? (
                      <a className="font-bold" onClick={() => resendConfirmationEmail()}> Resend confirmation email?</a>
                    ) : null
                  }
                </div>
              ) : null
            }

            <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" onSubmit={(e) => {
                e.preventDefault();

                setLoggingIn(true);
                setFlashType(null);
                setFlashMessage(null);
                setAllowConfirmationResend(null);

                fetch('/api/login', {
                  method: 'POST',
                  body: JSON.stringify({
                    username: usernameInputRef.current.value,
                    password: passwordInputRef.current.value,
                  }),
                  headers: {
                    'Content-Type': 'application/json',
                  }
                })
                .then((resp) => resp.json())
                .then(({success, msg, allow_confirmation_resend: allowConfirmationResend}) => {
                  if (success) return window.location.href = '/';

                  setAllowConfirmationResend(allowConfirmationResend);

                  setFlashMessage(msg);
                  setFlashType(success ? 'success' : 'error');
                })
                .finally(() => {
                  setLoggingIn(false);
                });
              }}>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                    Email or phone
                  </label>
                  <div className="mt-2">
                    <input
                      id="username"
                      name="username"
                      ref={usernameInputRef}
                      type="username"
                      autoComplete="username"
                      required
                      placeholder='Email or verified phone number'
                      className="block w-full rounded-md border-0 bg-white/5 px-2 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      ref={passwordInputRef}
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="space-y-4">
                  <a onClick={() => navigate('/reset')} className="font-semibold text-orange-400 hover:text-orange-300 text-sm">
                    Forgot password?
                  </a>
                  <button
                    type="submit"
                    disabled={loggingIn}
                    className="disabled:opacity-50 flex items-center w-full justify-center rounded-md bg-orange-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                  >
                    {loggingIn ? <Spinner className="-ml-1 mr-3 h-5 w-5 text-white"/> : null}
                    <span>Sign in</span>
                  </button>
                  <div className="flex items-center justify-center space-x-2">
                    <span>New to fuse?</span>
                    <a className='text-md font-semibold' href='/signup'>Join now</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )
      }
    </div>
  )
}
