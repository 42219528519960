import parsePhoneNumberFromString from 'libphonenumber-js'
import {useState} from "react";
import {useSelector} from "react-redux";
import {Button} from "../ui/Button";
import {savePhoneNumber as updatePhoneNumber} from './utils';
import Spinner from "../Spinner";
import {toast} from "react-toastify";
import PhoneNumberInput from "../ui/PhoneNumberInput";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";
import {InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot} from "../ui/InputOtp";
import {Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogFooter} from "../ui/Dialog";

export default function PhoneVerification({next}) {
  const {user} = useSelector(state => state.user);
  const [newPhoneNumber, setNewPhoneNumber] = useState(user.phone);
  const [verificationError, setVerificationError] = useState();
  const [verificationStarted, setVerificationStarted] = useState(false);
  const [verificationStarting, setVerificationStarting] = useState(false);
  const [savingUser, setSavingUser] = useState(false);
  const [verifyingOtp, setVerifyingOtp] = useState(false);
  const [editPhone, setEditPhone] = useState(false);

  function savePhoneNumber() {
    setSavingUser(true);
    setVerificationStarted(false);

    updatePhoneNumber(newPhoneNumber).then(({success, msg}) => {
      if (success) {
        toast.success('Phone number updated!', DEFAULT_TOAST_CONFIG);
        setEditPhone(false);
        setVerificationStarting(false);

        return;
      }

      setVerificationError(msg);
    })
    .finally(() => setSavingUser(false));
  }

  function startVerification() {
    setVerificationStarting(true);

    fetch('/api/twilio/start', {method: 'POST'})
    .then(resp => resp.json())
    .then(({success, msg}) => {
      if (!success) {
        toast.info(`${msg}. Proceeding to next step.`, DEFAULT_TOAST_CONFIG);
        setTimeout(() => next()({verifiedPhone: true}), 4000);
        return;
      }

      setVerificationStarted(true);
      toast.success('Verification started, check your messages for a 6 digit code.', DEFAULT_TOAST_CONFIG);
    });
  }

  function verifyOtp(code) {
    setVerifyingOtp(true);

    fetch('/api/twilio/verify', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({code}),
    })
    .then((resp) => resp.json())
    .then(({success}) => {
      if (!success) {
        toast.error('The code you submitted was not valid. Resend and try again.', DEFAULT_TOAST_CONFIG);
        return;
      }

      toast.success('Phone number verified!', DEFAULT_TOAST_CONFIG);

      next()({verifiedPhone: true});
    })
    .catch(() => {
      toast.error('Something went wrong. Sending you a new code now.', DEFAULT_TOAST_CONFIG);
      startVerification();
    })
    .finally(() => {
      setVerifyingOtp(false);
    });
  }

  if (!user.phone) return null;

  return (
    <div className='text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
      <h2 className='text-2xl font-semibold'>Verify your phone number</h2>
      <h2 className='text-sm'>Your phone number {parsePhoneNumberFromString(user.phone, 'US').formatNational()}</h2>
      <div className='py-10 space-y-10'>
        <div className='flex justify-center'>
          {
            verificationStarted ? (
              <InputOTP disabled={verifyingOtp} maxLength={6} onComplete={verifyOtp}>
                <InputOTPGroup>
                  <InputOTPSlot index={0}/>
                  <InputOTPSlot index={1}/>
                  <InputOTPSlot index={2}/>
                </InputOTPGroup>
                <InputOTPSeparator/>
                <InputOTPGroup>
                  <InputOTPSlot index={3}/>
                  <InputOTPSlot index={4}/>
                  <InputOTPSlot index={5}/>
                </InputOTPGroup>
              </InputOTP>
            ) : (
              <Button disabled={verificationStarting} onClick={startVerification} className='space-x-2'>
                {verificationStarting ? <Spinner className='h-5 w-5' /> : null}
                <span>{verificationStarting ? 'Starting' : 'Start'} phone verification</span>
              </Button>
            )
          }
        </div>

        {
          verifyingOtp ? (
            <div className='flex justify-center items-center space-x-2'>
              <Spinner className='h-5'/>
              <span>Verifying...</span>
            </div>
          ) : null
        }

        <div className='flex justify-center space-x-5 mt-5'>
          {verificationStarted ? <Button variant='link' onClick={startVerification}>Resend code</Button> : null}
          <Dialog open={editPhone} onOpenChange={setEditPhone}>
            <DialogTrigger asChild>
              <Button variant='link'>Edit phone number</Button>
            </DialogTrigger>
            <DialogContent className='p-5 bg-gray-800'>
              <DialogHeader>
                <DialogTitle>Edit phone number</DialogTitle>
                <div className='py-10'>
                  <PhoneNumberInput defaultValue={user.phone} onChange={(valid, phone) => {
                    setVerificationError(valid);
                    setNewPhoneNumber(phone);
                  }} />
                  {verificationError ? <div className='text-sm text-red-400 text-right'>{verificationError}</div> : null}
                </div>
                <DialogFooter>
                  <Button disabled={savingUser} onClick={savePhoneNumber}>
                    {savingUser ? <Spinner className='h-5 mr-2' /> : null}
                    <span>{savingUser ? 'Saving' : 'Save'}</span>
                  </Button>
                </DialogFooter>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  )
}
