import {
  Cog6ToothIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  EyeSlashIcon, SpeakerWaveIcon, SpeakerXMarkIcon, StarIcon,
  TagIcon,
} from "@heroicons/react/24/outline";
import {Fragment, useContext, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import ChannelContext from "../Contexts/ChannelContext";
import useSocket from "../hooks/useSocket";
import {MODAL_IDS, showModal} from "../reducers/modalsSlice";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "../ui/Dropdown";
import {Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle} from "../ui/Sheet";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";
import SettingsForm from "./SettingsForm";

export default function ChannelHeaderChannelTitleDropdownMenu({showTags, toggleShowTags}) {
  const [socket] = useSocket();
  const {channelId} = useContext(ChannelContext);
  const channel = useSelector(state => state.channels[channelId]);
  const [showChannelSettings, setShowChannelSettings] = useState(false);
  const dispatch = useDispatch();

  function toggleStarred(channel) {
    socket.emit('channels_users:update', {channelId: channel.id, starred: !channel.starred}, ({success, starred}) => {
      if (!success) {
        return toast.error(`The conversation could not be ${starred ? 'starred' : 'unstarred'}.`, DEFAULT_TOAST_CONFIG);
      }

      toast.success(`Your conversation has been ${starred ? 'starred.' : 'unstarred!'}`, DEFAULT_TOAST_CONFIG);
    });
  }

  function toggleMute(channel) {
    socket.emit('user:state:toggle_mute', {channelId: channel.id, muted: channel.muted}, ({success, muted}) => {
      if (!success) {
        return toast.error(`The conversation could not be ${muted ? 'muted' : 'unmuted'}.`, DEFAULT_TOAST_CONFIG);
      }

      toast.success(`Your conversation has been ${muted ? 'muted.' : 'unmuted!'}`, DEFAULT_TOAST_CONFIG);
    });
  }

  return (
    <>
      <Sheet open={showChannelSettings} onOpenChange={setShowChannelSettings}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>{channel.slug}</SheetTitle>
            <SheetDescription>Edit settings, invite users, or archive your conversation</SheetDescription>
          </SheetHeader>
          <div className='mt-5'>
            <SettingsForm onClose={() => setShowChannelSettings(false)} channel={channel} />
          </div>
        </SheetContent>
      </Sheet>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <EllipsisVerticalIcon className="h-7 w-7 hover:text-gray-100 transition cursor-pointer" />
        </DropdownMenuTrigger>
        <DropdownMenuContent side='bottom' align='end'>
          <DropdownMenuItem className='space-x-2' onClick={() => toggleStarred(channel)}>
            {
              channel.starred ? (
                <>
                  <StarIcon className='h-5 w-5 fill-amber-500' />
                  <span>Unstar</span>
                </>
              ) : (
                <>
                  <StarIcon className='h-5 w-5' />
                  <span>Star</span>
                </>
              )
            }
          </DropdownMenuItem>
          <DropdownMenuItem className='space-x-2' onClick={() => toggleMute(channel)}>
            {
              channel.muted ? (
                <>
                  <SpeakerWaveIcon className='h-5 w-5' />
                  <span>Unmute</span>
                </>
              ) : (
                <>
                  <SpeakerXMarkIcon className='h-5 w-5' />
                  <span>Mute</span>
                </>
              )
            }
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem className='space-x-2' onClick={() => {
            setShowChannelSettings(true);
          }}>
            <Cog6ToothIcon className='h-5 w-5' />
            <span>Settings</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
