import {Bars3Icon} from "@heroicons/react/24/outline";
import {useContext} from "react";
import DashboardContext from "./Contexts/DashboardContext";

export default function NavigationToggle() {
  const {toggleShowSidebar} = useContext(DashboardContext);

  return (
    <div className='lg:hidden' onClick={() => toggleShowSidebar()}>
      <span className="sr-only">Open sidebar</span>
      <Bars3Icon role='button' className="h-7 w-7" aria-hidden="true"/>
    </div>
  );
}
