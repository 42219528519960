import useSocket, {useSocketEvent} from "../hooks/useSocket.js";
import {useState} from "react";
import Spinner from "../Spinner";

export default function withSocket(Component) {
  return (props) => {
    const [, connected] = useSocket();
    const [initialConnection, setInitialConnection] = useState(false);
    const [reconnectAttempt, setReconnectAttempt] = useState(null);
    const [disconnected, setDisconnected] = useState(null);

    useSocketEvent('connect', () => {
      setInitialConnection(true);
      setDisconnected(false);
      setReconnectAttempt(0);
    });

    useSocketEvent('reconnect_attempt', (attempt) => {
      console.log('Reconnecting', attempt);
      setReconnectAttempt(attempt);
    });

    useSocketEvent('reconnect', (attempt) => {
      console.log('Reconnecting', attempt);
      setReconnectAttempt(attempt);
    });

    useSocketEvent('disconnect', () => {
      console.error('We have been disconnected');
      setDisconnected(true);
    });

    return (
      <>
        <Component {...props} />
        {
          initialConnection && !connected && !disconnected && (
            <div
              className="font-bold blink flex flex-col justify-center items-center fixed bottom-0 z-50 w-full h-12 bg-green-500 text-center">
              <div>Connecting</div>
            </div>
          )
        }
        {
          !connected && disconnected && reconnectAttempt > 3 && (
            <div
              className="font-bold blink flex flex-col justify-center items-center fixed bottom-0 z-50 w-full h-12 bg-orange-500 text-center">
              <div>Reconnecting...</div>
            </div>
          )
        }
        {
          disconnected ? (
            <div className="p-5 rounded shadow font-bold ring-2 ring-gray-300 backdrop-blur bg-opacity-80 space-x-2 flex justify-center items-center fixed bottom-5 right-5 z-50 h-12 bg-gray-800 text-center">
              <Spinner className='h-5 w-5'/>
              <span>Connecting...</span>
            </div>
          ) : null
        }
      </>
    )
  }
}
