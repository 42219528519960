import {
  TvIcon,
} from "@heroicons/react/24/outline";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast, ToastContainer} from "react-toastify";
import {useLocation} from "wouter";
import ModalArchiveChannel from "./Channel/ModalArchiveChannel";
import ChannelFloater from "./ChannelFloater";
import MiniSidebar from "./Dashboard/MiniSidebar";
import EventManager from "./EventManager";
import withSocket from "./hoc/withSocket";
import useAppLocation from "./hooks/useAppLocation";
import useOnlineBeacon from "./hooks/useOnlineBeacon";
import useSocket, {useSocketEvent} from "./hooks/useSocket";
import MobileNav from "./Nav/MobileNav";
import NavigationToggle from "./NavigationToggle";
import {setChannels, updateChannel} from "./reducers/channelsSlice";
import {MODAL_IDS, showModal} from "./reducers/modalsSlice";
import {setSpaces} from "./reducers/spacesSlice";
import {setLabels, setTags} from "./reducers/tagsSlice";
import {setUser} from "./reducers/userSlice";
import SocketDebugger from "./SocketDebugger";
import UserSetupBanner from "./UserSetup/UserSetupBanner";
import DashboardContext from './Contexts/DashboardContext';
import {Panel, PanelGroup, PanelResizeHandle} from 'react-resizable-panels';
import Sidebar from './Dashboard/Sidebar';
import UserContext from "./Contexts/UserContext";
import {requestNotificationPermission} from "./utils/Notify";
import {DEFAULT_TOAST_CONFIG} from "./utils/toast";
import 'react-toastify/dist/ReactToastify.css';
import 'tippy.js/dist/tippy.css';

function Dashboard({children}) {
  const [socket, connected] = useSocket();
  const me = useSelector((state) => state.user);
  const [showSidebar, setShowSidebar] = useState(false)
  const [, navigate] = useLocation();
  const dispatch = useDispatch();
  const [activeApp, {activeSpace}] = useAppLocation();

  useOnlineBeacon();

  useEffect(() => {
    if (activeApp !== 'chat' || activeSpace) return

    navigate('/chat/all');
  }, [activeSpace]);

  useEffect(requestNotificationPermission, []);

  useEffect(() => {
    if (!connected || !socket) return;

    getMe();
    socket.emit('user:unread_message_total');
  }, [socket, connected]);

  useSocketEvent('tag:added', getMe);
  useSocketEvent('tag:updated', getMe);
  useSocketEvent('tag:removed', getMe);

  useSocketEvent('channels:invitation', ({channel}) => {
    getMe().then(() => {
      toast.info(
        (
          <div className="space-y-2">
            <p className="text-slate-200 leading-tight text-sm">You have been added to a new channel</p>
            <strong className="block text-slate-200 text-sm">{channel.slug}</strong>
            <p className="text-xs text-slate-300">Click for options</p>
          </div>
        ),
        {
          ...DEFAULT_TOAST_CONFIG,
          autoClose: false,
          icon: (<TvIcon className="h-5 w-5 text-orange-500" />),
          onClick: () => {
            setShowSidebar(true);
            dispatch(showModal({
              modalId: MODAL_IDS.MODAL_CONFIRM_APPEND,
              data: {channelId: channel.id, spaceId: '__all_channels'},
            }));
          }
        });
    });
  });

  useSocketEvent(`updated_user_state`, ({spaces, channels}) => {
    if (spaces) dispatch(setSpaces(spaces));
    if (channels) dispatch(setChannels(channels));
  });

  useSocketEvent('channel:updated', ({updatedChannel}) => {
    dispatch(updateChannel({channelId: Number.parseInt(updatedChannel.id), updatedChannel}));
    getMe();
  });

  useSocketEvent('new_channel', () => {
    toast.info(`You have a new channel!`, DEFAULT_TOAST_CONFIG)
    getMe();
  });

  useSocketEvent('user:me', () => {
    getMe();
  });

  useSocketEvent('profile:claim:approved', ({newChannelCount}) => {
    toast.info(`Your profile claim request has been approved! ${newChannelCount ? `${newChannelCount} message requests have been added!` : ''}`, {
      ...DEFAULT_TOAST_CONFIG,
      autoClose: false,
      onClick: () => navigate('/'),
    });

    getMe();
  });

  async function getMe() {
    return new Promise((resolve) => {
      socket.emit('me', null, (me) => {
        dispatch(setUser(me));
        dispatch(setSpaces(me.user.state.spaces));
        dispatch(setChannels(me.user.channels));
        dispatch(setTags(me.user.labels));
        dispatch(setLabels(me.user.labels));
        resolve();
      });
    });
  }

  function toggleShowSidebar(open) {
    setShowSidebar(typeof open === "boolean" ? open : !showSidebar);
  }

  return (
    <DashboardContext.Provider value={{
      showSidebar,
      toggleShowSidebar,
    }}>
      <div className='h-full flex flex-col justify-between'>
        <UserSetupBanner />
        <div className='lg:hidden shrink py-3 pl-3 border-b border-gray-600'>
          <NavigationToggle />
        </div>
        <div className='h-full w-full grow overflow-y-auto'>
          <EventManager />
          <UserContext.Provider value={me}>
            <PanelGroup autoSaveId="appPanel" direction="horizontal">
              <Panel className='hidden lg:block' defaultSize={23} minSize={15}>
                <div className='flex justify-between grow h-full w-full relative bg-gray-900'>
                  <MiniSidebar/>
                  <Sidebar/>
                </div>
              </Panel>
              <PanelResizeHandle className='lg:block hidden w-[2px] bg-gray-600 hover:bg-orange-500 transition-colors'/>
              <Panel>
                <div className='bg-transparent overflow-auto h-full relative'>
                  {children}
                </div>
              </Panel>
            </PanelGroup>
            <SocketDebugger/>
            <ModalArchiveChannel/>
            <div className="z-50 fixed bottom-0.5 transition-y-full right-10 md:w-[30rem] h-3/4 flex space-x-5 hidden">
              <div className="min-h-[35em] ring-gray-500 w-full rounded-sm transition-all bg-gray-800 ring-2 text-gray-500 shadow-xl">
                <div className="relative h-full overflow-x-hidden">
                  <div className="flex flex-col justify-between bg-gray-700 relative h-full">
                    <div className='h-[2000px]'>test floater</div>
                  </div>
                </div>
              </div>
            </div>
            <ChannelFloater />
            <ToastContainer
              bodyClassName="bg-slate-900"
              toastClassName="p-2 bg-slate-900"
              progressClassName="bg-orange-500"
              position="bottom-center"
              autoClose={false}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
              theme="dark"
            />
          </UserContext.Provider>
        </div>
        <div className='shrink'>
          <MobileNav/>
        </div>
      </div>
    </DashboardContext.Provider>
  );
}

export default withSocket(Dashboard);
