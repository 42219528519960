import {createContext} from 'react';
export default createContext({
  channelId: null,
  typing: false,
  setAttachmentUuids: () => {},
  setShowCompanyInfoSlideover: () => {},
  setShowCloseOptions: () => {},
  showCloseOptions: false,
  channelLocked: false,
  channelIsArchived: false,
  showMessageList: false,
  lockToBottom: false,
  membersMap: {},
  name: null,
});
