import connect from "../socket/socket";
import {useEffect, useState} from "react";

export default function useSocket() {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (socket) return;
    setSocket(connect());
  }, [socket]);

  return [socket, socket?.connected];
}

export function useSocketEvent(event, callback, deps = []) {
  const [socket, connected] = useSocket();

  useEffect(() => {
    if (!socket) return;

    function handler(data) {
      callback(data);
    }

    socket.on(event, handler);

    return () => {
      socket.off(event, handler);
    }
  }, [socket, connected, event, ...deps]);
}

export function useSocketEventWithFilter(event, filter, callback, debug = false) {
  if (debug) console.log('useSocketWithFilter', event, filter);
  useSocketEvent(event, (payload) => {
    if (debug) console.log('useSocketWithFilter: got event', event, filter, payload);
    const [key, val] = filter;
    // TODO: This is a weak comparison, the database should be cast values correctly. An ORM will help here
    if (payload[key] != val) {
      if (debug) console.log('useSocketWithFilter: failed compare', filter, payload[key], val);
      return;
    }
    if (debug) console.log('useSocketWithFilter: callback', event, filter);

    callback(payload);
  });
}
