import {CheckIcon} from "@heroicons/react/20/solid";
import {ChatBubbleLeftRightIcon, TagIcon,} from "@heroicons/react/24/outline";
import {ChevronUpDownIcon} from "@heroicons/react/24/outline/index.js";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Search from './Search';
import useAppLocation from "../hooks/useAppLocation";
import {setSort} from "../reducers/channelGridSlice";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "../ui/Dropdown";

export const SORT_OPERATIONS = {
  latestMessageDesc: {label: 'Newest messages', desc: true, key: 'latestMessage'},
  latestMessageAsc: {label: 'Oldest messages', desc: false, key: 'latestMessage'},
  insertedAtAsc: {label: 'Newest channels', desc: false, key: 'insertedAt'},
  insertedAtDesc: {label: 'Oldest channels', desc: true, key: 'insertedAt'},
  nameDesc: {label: 'By name, A-Z', desc: true, key: 'name'},
  nameAsc: {label: 'By name, Z-A', desc: false, key: 'name'},
};

const sortOperationKeys = Object.keys(SORT_OPERATIONS);

export default function Header() {
  const [, {activeSpace, activeLabel}] = useAppLocation();
  const {sort} = useSelector(state => state.channelGrid);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!activeSpace) return;

    dispatch(setSort(activeSpace.sorted));
  }, [activeSpace]);

  if (!activeSpace) return null;

  return (
    <>
      <header className="h-16 bg-gray-800 border-b border-white/5 px-2 py-2 sm:px-6 sm:py-3 lg:px-4 flex">
        <div className="flex justify-between w-full">
          <div className="flex items-center space-x-1">
            {
              activeSpace.id || activeSpace.channels.length ? (
                <div className='flex items-center space-x-2.5'>
                  {activeLabel ? <TagIcon className='h-5' /> : <ChatBubbleLeftRightIcon className="h-5 w-5" />}
                  <h1 className='font-semibold text-base leading-7 text-white'>
                    {activeSpace.name}
                  </h1>
                </div>
              ) : null
            }
          </div>

          <div className='relative w-1/2'>
            <Search />
          </div>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className='flex items-center space-x-2 text-sm shrink'>
                <span>{sort ? SORT_OPERATIONS[sort].label : 'Sort'}</span>
                <ChevronUpDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent side='bottom' className='mr-5'>
              {
                sortOperationKeys.map((opKey) => {
                  return (
                    <DropdownMenuItem key={opKey} className='space-x-2' onClick={() => dispatch(setSort(opKey))}>
                      <span>{SORT_OPERATIONS[opKey].label}</span>
                      {
                        opKey === sort ? (
                          <CheckIcon className='h-4 w-4' />
                        ) : null
                      }
                    </DropdownMenuItem>
                  )
                })
              }
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </header>
    </>
  );
}
