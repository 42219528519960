export function upload(url, file) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
      xhr.status === 200 ? resolve() : reject();
    };

    xhr.open('PUT', url);
    xhr.send(file);
  });
}
