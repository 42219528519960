import {useCallback} from "react";
import useSocket from './useSocket';
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";
import {setAdhocChannel} from "../reducers/channelsSlice";
import {Profile} from "../Switchboard/types";

export default function useCreateDmChannel(): (profile: Profile, callback?: (resp: any) => void) => void {
  const [socket] = useSocket();
  const dispatch = useDispatch();

  return useCallback((profile: Profile, callback?: (resp: any) => void) => {
    socket?.emit('new_channel', {
      channelName: null,
      channelDescription: null,
      status: 'draft',
      invitedUsers: [profile],
      invitedEmails: [],
    }, (resp) => {
      const {success, msg, channel, existingChannelId} = resp;

      if (!success && !existingChannelId) {
        toast.error(msg, DEFAULT_TOAST_CONFIG);
        return;
      }

      dispatch(setAdhocChannel(existingChannelId || channel.id));
      callback && callback(resp);
    });
  }, [socket]);
}
