import * as React from "react"
import {cn} from '../utils/classes';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "bg-slate-700 px-1.5 block w-full rounded-md py-0.5 text-white shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 border border-gray-800 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6",
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = "Input"

export { Input }
