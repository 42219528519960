import {combineReducers} from 'redux';
import channelsReducer from "./channelsSlice";
import spacesReducer from "./spacesSlice";
import membersReducer from "./membersSlice";
import userReducer from "./userSlice";
import tagsReducer from "./tagsSlice";
import modalsReducer from "./modalsSlice";
import channelGridReducer from "./channelGridSlice";
import globalReducer from "./globalSlice";

const rootReducer = combineReducers({
  user: userReducer,
  channels: channelsReducer,
  spaces: spacesReducer,
  memberStatuses: membersReducer,
  tags: tagsReducer,
  modals: modalsReducer,
  channelGrid: channelGridReducer,
  global: globalReducer,
});

export default rootReducer;
