import {ArchiveBoxIcon} from "@heroicons/react/24/outline";
import validator from "email-validator";
import {useContext, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TimeAgo from "react-timeago";
import {toast} from "react-toastify";
import UserContext from "../Contexts/UserContext";
import useSocket from "../hooks/useSocket";
import AutocompleteMembers from "../modals/AutocompleteMembers";
import {getChannelMembersThunk} from "../reducers/membersSlice";
import {classNames} from '../utils/classes';
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";

export function ArchiveChannelForm({channelId, close}) {
  const [socket] = useSocket();
  const channel = useSelector(state => state.channels[channelId]);
  const [archiveConfirmation, setArchiveConfirmation] = useState(null);

  function archiveChannel() {
    if (window.confirm('Are you sure you want to archive this channel? This cannot be undone.')) {
      socket.emit('channel:archive', { channelId }, () => {
        toast.success(`${channel.slug} has been archived.`, DEFAULT_TOAST_CONFIG);
        close();
      });
    }
  }

  return (
    <div className="flex justify-between space-x-2">
      <div className="grow w-full rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-500">
        <input
          type="text"
          name="archive_channel"
          id="archive_channel"
          onChange={({target: {value}}) => setArchiveConfirmation(value)}
          placeholder={`Type the channel name '${channel.slug}' exactly.`}
          autoComplete="false"
          className="w-full border-0 bg-transparent py-1.5 pl-1 text-white focus:ring-0 sm:text-sm sm:leading-6"
        />
      </div>
      <button
        onClick={archiveChannel}
        disabled={archiveConfirmation !== channel.slug}
        className="flex space-x-2 rounded-md bg-red-500 disabled:bg-gray-500 disabled:opacity-50 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
      >
        <ArchiveBoxIcon className="text-white h-5 w-5" />
        <span className="hidden md:inline-block">Archive</span>
      </button>
    </div>
  )
}

export default function SettingsForm({onClose, channel}) {
  const {slug: name, description, id: channelId} = channel;

  const [socket] = useSocket();
  const {user} = useContext(UserContext);
  const [channelName, setChannelName] = useState(name);
  const [channelDescription, setChannelDescription] = useState(description);
  const channelMembers = useSelector(state => Object.keys(state.channels[channelId].members).reverse());
  const {members: channelMembersWithActive, invitedMembers: inviteesByInvitationId = {}} = useSelector(state => state.channels[channelId]);
  const invitedMembers = useMemo(() => Object.values(inviteesByInvitationId), [inviteesByInvitationId]);
  const members = useSelector(state => state.memberStatuses);
  const dispatch = useDispatch();

  function close() {
    onClose();
  }

  function save() {
    socket.emit('channel:update', {channelId, slug: channelName, description: channelDescription}, ({success, msg}) => {
      if (!success) return toast.error(msg, DEFAULT_TOAST_CONFIG);
      toast.success(`${channelName || 'Your conversation'} has been updated!`, DEFAULT_TOAST_CONFIG);
    });
  }

  function removeUser(member) {
    if (window.confirm('Are you sure you would like to remove this user?')) {
      socket.emit('channel:user:remove', { userIdToRemove: member.user_id, channelId }, ({success}) => {
        if (success) toast.success('User has been been removed.', DEFAULT_TOAST_CONFIG);
        if (!success) toast.error('Could not remove user.', DEFAULT_TOAST_CONFIG);
      });
    }
  }

  function addUser(userId, email) {
    socket.emit('channel:user:invite', { userIdToInvite: userId, emailToInvite: email, channelId }, ({success}) => {
      if (success) toast.success('User has been invited.', DEFAULT_TOAST_CONFIG);
      if (!success) toast.error('Could not add user.', DEFAULT_TOAST_CONFIG);
    });
  }

  function removeInvitation(invite) {
    if (!window.confirm('Are you sure you would like to revoke this invitation?')) return;

    socket.emit('channel:invite:remove', {channelId, invitation: {id: invite.id}}, ({success, msg}) => {
      success ? toast.success(msg, DEFAULT_TOAST_CONFIG) : toast.error(msg, DEFAULT_TOAST_CONFIG);
      dispatch(getChannelMembersThunk({channelId}));
    });
  }

  const isChannelCreator = channel.created_by === user.id;
  const excludedMembers = Object.values(channelMembers).map(id => ({id}));

  return (
    <div className='h-full'>
      <div className="divide-gray-600 divide-y space-y-10">
        <div className="grid grid-cols-1 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <label htmlFor="channel_name" className="block text-sm font-medium leading-6 text-white">
              Name
            </label>
            <div className="mt-2">
              <div className="rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-500">
                <input
                  type="text"
                  name="channel_name"
                  id="channel_name"
                  autoComplete="false"
                  disabled={channel.created_by !== user.id}
                  onChange={({target: {value}}) => setChannelName(value)}
                  defaultValue={name}
                  className="flex-1 w-full border-0 bg-transparent py-1.5 pl-1 text-white focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="My short channel name"
                />
              </div>
            </div>
          </div>
          <div className="sm:col-span-6">
            <label htmlFor="channel_description" className="block text-sm font-medium leading-6 text-white">
              Description
            </label>
            <div className="mt-2">
              <div className="rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-500">
                <input
                  type="text"
                  name="channel_description"
                  id="channel_description"
                  autoComplete="false"
                  disabled={channel.created_by !== user.id}
                  onChange={({target: {value}}) => setChannelDescription(value)}
                  defaultValue={description}
                  className="flex-1 w-full border-0 bg-transparent py-1.5 pl-1 text-white focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="My short channel name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <h2 className="text-base font-semibold leading-7 text-white">Participants</h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            A list of people in this channel.
          </p>
          <ul role="list" className="divide-y divide-gray-500">
            {channelMembers.map((memberId) => {
              const member = members[memberId];
              if (!member) return null;

              const isCurrentUser = member.user_id === user.id;
              const personIsChannelCreator = member.user_id === channel.created_by;
              const isActive = channelMembersWithActive[member.user_id]?.active || false;

              return (
                <li key={`members_${member.username}_${member.id}`} className={classNames(`flex justify-between gap-x-6 py-5`)}>
                  <div className={classNames(member.active ? '' : 'opacity-50 pointer-events-none', 'flex min-w-0 gap-x-4')}>
                    <img className="h-12 w-12 flex-none rounded-md bg-gray-800" src={member.avatar_url} alt="" />
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-white">{member.first_name} {member.last_name}</p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-400">{personIsChannelCreator ? 'Admin' : 'Member'}</p>
                    </div>
                  </div>
                  <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                    {
                      isChannelCreator && !personIsChannelCreator ? (
                        <>
                          {
                            isActive ? (
                              <p className="text-sm leading-6 font-bold hover:text-red-500 text-white cursor-pointer pointer-events-auto transition" onClick={() => {
                                removeUser(member);
                              }}>{isCurrentUser ? 'Leave' : 'Remove'}</p>
                            ) : (
                              <p
                                className={classNames('text-sm leading-6 font-bold hover:text-green-500 text-white cursor-pointer opacity-100 pointer-events-auto transition')}
                                onClick={() => {
                                  addUser(member.user_id);
                                }}
                              >Re-invite</p>
                            )
                          }
                        </>
                      ) : (
                        <p className="text-sm leading-6 font-normal italic text-white transition">
                          &nbsp;
                        </p>
                      )
                    }
                    {member.status !== 'ONLINE' ? (
                      <p className="mt-1 text-xs leading-5 text-gray-400">
                        Seen <TimeAgo minPeriod={60} formatter={(val, unit) => `${val} ${unit}${val > 1 ? 's' : ''} ago`} date={member.status_updated_at} />
                      </p>
                    ) : (
                      <div className="mt-1 flex items-center gap-x-1.5">
                        <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                          <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                        </div>
                        <p className="text-xs leading-5 text-gray-400">Online</p>
                      </div>
                    )}
                  </div>
                </li>
              )
            })}
          </ul>
          {
            isChannelCreator ? (
              <>
                <AutocompleteMembers
                  validateUnknown={(query) => validator.validate(query)}
                  exclude={excludedMembers}
                  setSelected={(selected) => {
                    addUser(selected.id);
                  }}
                  selectUnknownMember={(email) => {
                    addUser(null, email);
                  }}
                />
                <div className="mt-4 flex justify-end">
                  <button
                    type="submit"
                    className="rounded-md bg-orange-500 px-2 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                  >
                    Add
                  </button>
                </div>
              </>
            ) : null
          }
        </div>
        <div className="pt-5">
          <h2 className="text-base font-semibold leading-7 text-white">Invitations</h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            A list of people you have invited but have not created an account yet.
          </p>
          <ul role="list" className="divide-y divide-x-5 divide-gray-500">
            {
              isChannelCreator && invitedMembers.length ? invitedMembers.map((invite) => {
                return (
                  <li key={invite.username} className={classNames(`flex justify-between gap-x-6 py-5`)}>
                    <div className={classNames(invite ? '' : 'opacity-50 pointer-events-none', 'min-w-0 gap-x-4')}>
                      <p className="text-xs font-semibold leading-6 text-white truncate">{invite.email}</p>
                      <p className="text-xs font-semibold leading-6 text-gray-300">
                        Invited {' '}
                        <TimeAgo formatter={(val, unit) => `${val} ${unit}${val > 1 ? 's' : ''} ago`} date={invite.invited_at} />
                      </p>
                    </div>
                    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end flex flex-col justify-center">
                      <p onClick={() => removeInvitation(invite)} className="text-sm leading-6 font-bold hover:text-red-500 text-white cursor-pointer pointer-events-auto transition">Revoke</p>
                    </div>
                  </li>
                )
              }) : (
                <>
                  { !isChannelCreator ? <p className="text-white pt-5 pb-0 text-sm">{invitedMembers.length} pending invitations</p> : null }
                </>
              )
            }
          </ul>
        </div>
        <div className="hidden">
          <h2 className="text-base font-semibold leading-7 text-white">Notifications</h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Determine when you would like to receive notifications.
          </p>
          <div className="space-y-10">
            <fieldset>
              <div className="mt-6 space-y-6">
                <div className="flex items-center gap-x-3">
                  <input
                    id="push-everything"
                    name="push-notifications"
                    type="radio"
                    className="h-4 w-4 border-white/10 bg-white/5 text-orange-600 focus:ring-orange-600 focus:ring-offset-gray-900"
                  />
                  <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-white">
                    Everything
                  </label>
                </div>
                <div className="flex items-center gap-x-3">
                  <input
                    id="push-email"
                    name="push-notifications"
                    type="radio"
                    className="h-4 w-4 border-white/10 bg-white/5 text-orange-600 focus:ring-orange-600 focus:ring-offset-gray-900"
                  />
                  <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-white">
                    When mentioned i.e. <span className="text-sm text-gray-300">@{user?.username}</span>
                  </label>
                </div>
                <div className="flex items-center gap-x-3">
                  <input
                    id="push-nothing"
                    name="push-notifications"
                    type="radio"
                    className="h-4 w-4 border-white/10 bg-white/5 text-orange-600 focus:ring-orange-600 focus:ring-offset-gray-900"
                  />
                  <label htmlFor="push-nothing" className="block text-sm font-medium leading-6 text-white">
                    No notifications
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        {isChannelCreator ? (
          <div className="pt-5">
            <h2 className="text-base font-semibold leading-7 text-red-500">Danger Zone</h2>
            <p className="mt-1 text-sm leading-6 text-gray-400">
              The actions you can take below are irreversible.
            </p>
            <div className="mt-2">
              <div className="sm:col-span-6">
                <label htmlFor="archive_channel" className="block text-sm font-medium leading-6 text-white">
                  Archive Channel
                </label>
                <div className="mt-2">
                  <ArchiveChannelForm channelId={channelId} close={close} />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="sticky w-full bottom-0 left-0 bg-gray-800 backdrop-blur-md bg-opacity-50 border-t border-gray-500 py-4 mt-6 flex items-center justify-end gap-x-6">
        <button type="button" className="text-sm font-semibold leading-6 text-white" onClick={close}>
          Close
        </button>
        <button
          onClick={save}
          className="rounded-md bg-orange-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
        >
          Save
        </button>
      </div>
    </div>
  )
}
