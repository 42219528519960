import {toast} from "react-toastify";
import connect from '../socket/socket';
import {DEFAULT_TOAST_CONFIG} from "./toast";
import {Profile} from "../Switchboard/types";

export function toggleContact(profile: Profile, callback?: () => void) {
  const socket = connect();
  const operation = profile.contact ? 'remove' : 'add'

  socket.emit(`profiles:contact:${operation}`, {
    contactId: profile.id
  }, ({success, msg}) => {
    const toaster = success ? toast.success : toast.error;

    toaster(msg, DEFAULT_TOAST_CONFIG);
    callback && callback();
  });
}
