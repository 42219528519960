import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import debounce from "lodash.debounce";
import connect from "../socket/socket";

const initialState = {
  adhocChannel: {
    id: null,
    hasDraftMessage: false,
  },
};

function applyMetadata(ch, stateCh) {
  return {
    messages: [],
    typing: null,
    members: {},
    maxSeenMessage: null,
    readStatuses: {},
    lockToBottom: false,
    tags: [],
    ...stateCh,
    ...ch,
    metadata: {
      name: ch.slug,
      latestMessage: ch.latest_message_date ? new Date(ch.latest_message_date) : null,
      insertedAt: ch.inserted_at,
    }
  }
}

const getUnreadMessageCount = debounce((socket, channelId) => {
  socket.emit('user:unread_message_count', {channelId});
}, 1000, { leading: true });

export const setMaxSeenMessageThunk = createAsyncThunk(
  'channels/setMaxSeenMessage',
  async ({id: targetChannelId, maxSeenMessage: maxSeenMessageId}, thunkAPI) => {
    const socket = connect();
    await socket.emitWithAck('last_seen_message', {channel_id: targetChannelId, lastSeenMessageId: maxSeenMessageId});
    console.log('setting last seen message');
    getUnreadMessageCount(socket, targetChannelId);

    thunkAPI.dispatch(setMaxSeenMessage(thunkAPI.getState().channels, {channelId: targetChannelId, maxSeenMessageId}));
  }
);

const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    setChannels(state, action) {
      if (!action.payload) return state;

      const channelsWithMetadata = action.payload.reduce((acc, ch) => {
        acc[ch.channel_id] = applyMetadata(ch, state[ch.channel_id]);
        return acc;
      }, {});

      return {...state, ...channelsWithMetadata};
    },
    setChannelLockToBottom(state, action) {
      state[action.payload.channelId].lockToBottom = action.payload.lockToBottom;
    },
    updateChannel(state, action) {
      state[action.payload.channelId] = {...state[action.payload.channelId], ...action.payload.updatedChannel};
    },
    appendManyMessages(state, action) {
      const {channelId, messages} = action.payload;
      state[channelId].messages.unshift(...messages);
    },
    setManyMessages(state, action) {
      const {channelId, messages} = action.payload;
      state[channelId].messages = messages;
    },
    prependManyMessages(state, action) {
      const {channelId, messages} = action.payload;
      state[channelId].messages.push(...messages);
    },
    appendMessage(state, {payload: {message, channelId}}) {
      state[channelId].messages.unshift(message);
    },
    updateMessage(state, {payload}) {
      state[payload.channel_id].messages = state[payload.channel_id].messages.map((message) => {
        if (message.id === payload.id) return {...message, ...payload};

        return message;
      });
    },
    setReadStatuses(state, action) {
      const {readStatuses, userId, channelId} = action.payload;
      const userMaxReadMessage = readStatuses.find((status) => status.id === userId)?.last_message_read_id;

      state[channelId].maxSeenMessage = userMaxReadMessage || -1;

      state[channelId].readStatuses = readStatuses.reduce((acc, status) => {
        acc[status.last_message_read_id] ||= [];
        acc[status.last_message_read_id].push(status);
        return acc;
      }, {});
    },
    setMaxSeenMessage(state, action) {
      state[action.payload.channelId].maxSeenMessage = action.payload.maxSeenMessageId;
    },
    setChannelMembers(state, action) {
      state[action.payload.channelId].members = action.payload.members.reduce((acc, member) => {
        acc[member.user_id] = member;
        return acc;
      }, {});

      state[action.payload.channelId].invitedMembers = action.payload.invitedMembers.reduce((acc, invitee) => {
        acc[invitee.id] = invitee;
        return acc;
      }, {});
    },
    setIsTyping(state, action) {
      const {channelId, typing} = action.payload;
      state[channelId].typing = typing;
    },
    removeMessage(state, action) {
      state[action.payload.channel_id].messages = state[action.payload.channel_id].messages.filter(({id}) => id !== action.payload.message_id);
    },
    addChannel(state, action) {
      state[action.payload.channelId] = applyMetadata(action.payload);
    },
    setAdhocChannelHasDraftMessage(state, payload) {
      state.adhocChannel.hasDraftMessage = payload;
    },
    setAdhocChannel(state, action) {
      state.adhocChannel.id = action.payload;
    },
    removeAdhocChannel(state) {
      state.adhocChannel = {id: undefined, hasDraftMessage: false};
    },
  },
});

export const {
  setChannels,
  setAdhocChannel,
  removeAdhocChannel,
  updateChannel,
  appendManyMessages,
  setManyMessages,
  setMaxSeenMessage,
  appendMessage,
  setChannelMembers,
  setChannelLockToBottom,
  setAdhocChannelHasDraftMessage,
  prependManyMessages,
  setReadStatuses,
  setIsTyping,
  updateMessage,
  removeMessage,
} = channelsSlice.actions;

export default channelsSlice.reducer;
