import {Fragment, useEffect, useMemo, useState} from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import useSocket from "../hooks/useSocket.js";

export default function AutocompleteMembers({
  exclude = [],
  setSelected,
  placeholder,
  selectUnknownMember,
  validateUnknown = () => false,
  allowUnknown = true
}) {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [canAddUnknown, setCanAddUnknown] = useState(allowUnknown);
  const [socket] = useSocket();

  const excludedMembersById = useMemo(() => {
    return exclude.reduce((acc, excluded) => {
      acc[excluded.id] = true;
      return acc;
    }, {});
  }, [exclude]);

  useEffect(() => {
    if (!socket) return;

    socket.emit('search_users', {query}, (results) => {
      results = results.filter(({id}) => !excludedMembersById[id]);
      if (!results.length) setCanAddUnknown(allowUnknown && validateUnknown(query));
      setResults(results);
    });
  }, [socket, query]);

  if (!socket) return null;

  return (
    <div className="ring-2 ring-gray-500 rounded-md">
      <Combobox onChange={({adhoc, option}) => {
        canAddUnknown && adhoc ? selectUnknownMember(option) : setSelected(option);
      }}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="bg-gray-800 w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-white focus:ring-0"
              placeholder={placeholder || `Search by name${allowUnknown ? ' or enter email ' : ' '}to invite...`}
              displayValue={(person) => person.name}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-600 divide-y divide-gray-500 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {results.length === 0 || !query ? (
                <div
                  className="relative cursor-default select-none py-2 px-4 text-white"
                >
                  {
                    results.length === 0 && query.length ? (
                      <>
                        {
                          canAddUnknown ? (
                            <Combobox.Option value={{adhoc: true, option: query}}>
                              <span><em>{query}</em> - Click to add email</span>
                            </Combobox.Option>
                          ) : 'Nothing found.'
                        }
                      </>
                    ) : ('Try an email address?')
                  }
                </div>
              ) : (
                results.map((user) => {
                  return (
                    <Combobox.Option
                      key={user.id}
                      className={({active}) =>
                        `relative cursor-default select-none py-2 px-4 ${
                          active ? 'bg-orange-500/50 text-white' : 'text-white'
                        }`
                      }
                      value={{adhoc: false, option: user}}
                    >
                      {({selected}) => (
                        <div
                          className={`block truncate flex items-center justify-between ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          <div className="flex items-center space-x-5">
                            <img src={user.avatarUrl} className="h-10 w-10 rounded-full" alt={`${user.first_name}'s profile picture`} />
                            <span>{user.firstName} {user.lastName}</span>
                          </div>
                          <div>Add</div>
                        </div>
                      )}
                    </Combobox.Option>
                  )
                })
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}
