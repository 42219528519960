import {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import useSocket from "../hooks/useSocket";
import {setUser} from "../reducers/userSlice";
import {Alert, AlertDescription} from "../ui/Alert";
import {Button} from "../ui/Button";
import {Dialog, DialogContent, DialogFooter, DialogTitle, DialogTrigger} from "../ui/Dialog";
import {Input} from "../ui/Input";
import {Switch} from "../ui/Switch";
import {Textarea} from "../ui/Textarea";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";
import AutocompleteCompany from "./AutocompleteCompany";
import CompanyAvatar from "./CompanyAvatar";
import CreateCompanyForm from "./CreateCompanyForm";
import UserAvatarForm from "./UserAvatarForm";

export function MyUserProfile() {
  const {user} = useSelector(state => state.user);
  const [profile, setProfile] = useState();
  const [editCompany, setEditCompany] = useState(false);
  const [company, setCompany] = useState();
  const [avatarUrl, setAvatarUrl] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [showCreateNewCompany, setShowCreateNewCompany] = useState(false);
  const [socket] = useSocket();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;

    socket?.emit('profiles:find', {profileId: user.id}, ({success, profile}) => {
      if (!success) return;

      setProfile(profile);
    });
  }, [socket, user]);

  useEffect(() => {
    if (!profile) return;

    setCompany(getUserCompanyData(profile));
  }, [profile]);

  function getUserCompanyData(profile) {
    if (!profile?.company) return {};

    return ({
      cashtag: profile.company.cashtag,
      name: profile.company.name,
      avatarUrl: profile.company.avatarUrl,
    });
  }

  function updateUser() {
    socket.emit('user:profile:update', {
      ...profile,
      avatarUrl,
      companyId: company.id,
    }, ({success, msg}) => {
      if (!success) return setErrorMessage(msg);

      socket.emit('me', null, (me) => dispatch(setUser(me)));

      toast.success('You profile has been saved and updated.', DEFAULT_TOAST_CONFIG);
    });
  }

  if (!profile) return null;

  return (
    <>
      <Dialog open={showCreateNewCompany} onOpenChange={setShowCreateNewCompany}>
        <DialogContent>
          <CreateCompanyForm
            onCancel={() => setShowCreateNewCompany(false)}
            onCreated={(company) => {
              setCompany(company);
              setEditCompany(false);
              setShowCreateNewCompany(false);
            }}
          />
        </DialogContent>
      </Dialog>
      <div className='p-5 pb-0 lg:w-3/4 2xl:w-1/2'>
        <h2 className='text-xl font-bold space-y-5'>Profile</h2>
        <div className='flex items-center justify-between mt-8'>
          <div className='flex items-center space-x-5'>
            <UserAvatarForm onSave={(avatarUrl) => setAvatarUrl(avatarUrl)} profile={profile}/>
            <div className='text-2xl font-bold'>
              <div>{profile.firstName} {profile.lastName}</div>
            </div>
          </div>
          <div className="space-y-4">
            {
              errorMessage ? (
                <Alert variant='destructive'>
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Alert>
              ) : null
            }
            <button
              onClick={updateUser}
              className="rounded-md disabled:opacity-50 bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div className="space-y-6 p-5 lg:w-3/4 2xl:w-1/2">
        <div className="border-b border-gray-600 pb-12">
          <h2 className="text-base my-4 font-semibold leading-7 text-gray-100">General</h2>
          <div className='space-y-5'>
            <div className='space-y-3'>
              <div className='flex items-center'>
                <label htmlFor="first-name" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  First name
                </label>
                <Input
                  type="text"
                  name="first-name"
                  id="first-name"
                  value={profile?.firstName}
                  onChange={({target: {value}}) => setProfile({...profile, firstName: value})}
                  autoComplete="given-name"
                  className="p-2 py-1"
                />
              </div>
              <div className='flex items-center'>
                <label htmlFor="last-name" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Last name
                </label>
                <Input
                  type="text"
                  name="last-name"
                  id="last-name"
                  value={profile?.lastName}
                  onChange={({target: {value}}) => setProfile({...profile, lastName: value})}
                  autoComplete="family-name"
                  className="p-2 py-1"
                />
              </div>
              <div className="flex items-center">
                <label htmlFor="email" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Email address
                </label>
                <Input
                  disabled
                  id="email"
                  name="email"
                  type="email"
                  defaultValue={user.email}
                  autoComplete="email"
                  className="p-2 py-1"
                />
              </div>
              <div className="flex">
                <label htmlFor="username" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Privacy
                </label>
                <div className='w-full'>
                  <div className='flex items-center space-x-2 text-sm'>
                    <Switch checked={profile?.isPublic} onCheckedChange={(checked) => setProfile({...profile, isPublic: checked})}/>
                    <div>{profile?.isPublic ? 'Public' : 'Private'}</div>
                  </div>
                  <div className='text-sm text-gray-300 space-y-4 mt-4'>
                    <p>
                      Choosing 'Public' makes your information visible in Fuse and allows others to view the information
                      you share.
                    </p>
                    <p>
                      Choosing 'Private' ensures your information is hidden from searches on Fuse, and only people who
                      you have existing conversations with or send message requests to can see it.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-600 pb-12">
          <h2 className="text-base my-4 font-semibold leading-7 text-gray-100">Professional info</h2>
          <div className='space-y-5'>
            <div className='space-y-3'>
              <div className='flex items-center'>
                <label htmlFor="company" className="w-1/3 block text-sm font-medium leading-6 text-gray-100">
                  Company
                </label>
                <div className="w-full flex items-center">
                  {
                    editCompany ? (
                      <div className='w-full'>
                        <AutocompleteCompany
                          setSelected={(company) => setCompany(company)}
                          setShowCreateNewCompany={(show) => {
                            setShowCreateNewCompany(show);
                            setEditCompany(show);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="flex justify-between items-center">
                        <div className='flex space-x-2 items-center'>
                          <CompanyAvatar avatarUrl={company?.avatarUrl} cashtag={company?.cashtag}/>
                          <div className="font-semibold">{company?.name}</div>
                        </div>
                      </div>
                    )
                  }
                  <span onClick={() => {
                    setEditCompany(!editCompany);
                    if (editCompany) setCompany(getUserCompanyData(profile));
                  }} className="text-xs shrink text-gray-400 cursor-pointer ml-2">
                    {editCompany ? 'Cancel' : 'Change'}
                  </span>
                </div>
              </div>
              <div className='flex items-center'>
                <label htmlFor="first-name" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Job Role
                </label>
                <Input
                  type="text"
                  name="job-role"
                  id="job-role"
                  value={profile?.jobRole}
                  onChange={({target: {value}}) => setProfile({...profile, jobRole: value})}
                  className="p-2 py-1"
                />
              </div>
              <div className='flex items-center'>
                <label htmlFor="last-name" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Title
                </label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  value={profile?.title}
                  onChange={({target: {value}}) => setProfile({...profile, title: value})}
                  className="p-2 py-1"
                />
              </div>
              <div className="flex items-center">
                <label htmlFor="email" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Industry Type
                </label>
                <Input
                  type="text"
                  name="industry-type"
                  id="industry-type"
                  disabled={!profile}
                  value={profile?.industryType}
                  onChange={({target: {value}}) => setProfile({...profile, industryType: value})}
                  className="p-2 py-1"
                />
              </div>
              <div className="flex items-center">
                <label htmlFor="email" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Industry
                </label>
                <Input
                  type="text"
                  name="industry"
                  id="industry"
                  value={profile?.industry}
                  onChange={({target: {value}}) => setProfile({...profile, industry: value})}
                  className="p-2 py-1"
                />
              </div>
              <div className="flex items-center">
                <label htmlFor="email" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Segment
                </label>
                <Input
                  type="text"
                  name="segment"
                  id="segment"
                  disabled={!profile}
                  value={profile?.insuranceSegment}
                  onChange={({target: {value}}) => setProfile({...profile, insuranceSegment: value})}
                  className="p-2 py-1"
                />
              </div>
              <div className="flex items-center">
                <label htmlFor="email" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  LOB Specialty
                </label>
                <Input
                  type="text"
                  name="lob-specialty"
                  id="lob-specialty"
                  value={profile?.lobSpecialty}
                  onChange={({target: {value}}) => setProfile({...profile, lobSpecialty: value})}
                  className="p-2 py-1"
                />
              </div>
              <div className="flex items-center">
                <label htmlFor="email" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Position
                </label>
                <Input
                  type="text"
                  name="position"
                  id="position"
                  disabled={!profile}
                  value={profile?.position}
                  onChange={({target: {value}}) => setProfile({...profile, position: value})}
                  className="p-2 py-1"
                />
              </div>
              <div className="flex">
                <label htmlFor="email" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Position description
                </label>
                <Textarea
                  name="position-description"
                  id="position-description"
                  disabled={!profile}
                  rows={3}
                  placeholder='Describe your position...'
                  value={profile?.positionDescription}
                  onChange={({target: {value}}) => setProfile({...profile, positionDescription: value})}
                  className="p-2 py-1"
                />
              </div>
              <div className="flex">
                <label htmlFor="email" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Bio
                </label>
                <Textarea
                  name="bio"
                  id="bio"
                  disabled={!profile}
                  rows={3}
                  placeholder='Describe your position...'
                  value={profile?.bio}
                  onChange={({target: {value}}) => setProfile({...profile, bio: value})}
                  className="p-2 py-1"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <h2 className="text-base my-4 font-semibold leading-7 text-gray-100">Account control</h2>
          <div className='space-y-5'>
            <div className="flex items-center">
              <div className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                Deactivation
              </div>
              <div className='w-full'>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button className='px-0' variant='link'>Delete account</Button>
                  </DialogTrigger>
                  <DialogContent className='p-5'>
                    <DialogTitle>Delete account</DialogTitle>
                    <div className='h-36 py-5'>You are about to delete your account. Are you sure?</div>
                    <DialogFooter>
                      <Button>No don't delete</Button>
                      <Button variant='ghost'>Yes, delete it</Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
