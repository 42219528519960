import {Fragment, useEffect, useState} from "react";
import {Combobox, Transition} from "@headlessui/react";
import {CheckIcon} from "@heroicons/react/20/solid";
import {ChevronUpDownIcon} from "@heroicons/react/24/outline/index.js";
import useSocket from "../hooks/useSocket";

export default function AutocompleteCompany({setSelected, setShowCreateNewCompany}) {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [socket] = useSocket();

  useEffect(() => {
    if (!socket) return;

    socket.emit('companies:search', {query}, setResults);
  }, [socket, query]);

  return (
    <div className="border border-white rounded-md">
      <Combobox onChange={setSelected}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="bg-gray-800 w-full border-none pt-1 pb-2 pl-3 pr-5 text-sm leading-5 text-white focus:ring-0"
              placeholder="Type a cashtag or company name..."
              displayValue={(company) => company ? company.name : null}
              onChange={(event) => {
                setSelected(event.target)
                setQuery(event.target.value);
              }}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options  className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-600 divide-y divide-gray-500 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {results.length === 0 || !query.length ? (
                <div
                  className="relative cursor-default select-none py-2 px-4 text-white"
                >
                  {
                    results.length === 0 && query ? (
                      <>
                        <div>Nothing found</div>
                      </>
                    ) : (
                      <span className="animate-pulse">Search by company name or $cashtag</span>
                    )
                  }
                </div>
              ) : (
                results.map((company) => {
                  return (
                    <Combobox.Option
                      key={company.id}
                      className={({active}) =>
                        `relative cursor-default select-none py-2 px-4 ${
                          active ? 'bg-orange-500/50 text-white' : 'text-white'
                        }`
                      }
                      value={company}
                    >
                      {({selected, active}) => (
                        <>
                        <span
                          className={`block truncate flex items-center space-x-5 ${
                            active ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          <img src={company.avatar_url} className="h-10 w-10 rounded-lg object-contain" alt={`${company.name}'s profile picture`} />
                          <div>
                            <p>{company.name}</p>
                            <p className="text-gray-200 text-xs">${company.cashtag}</p>
                          </div>
                        </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-teal-600'
                              }`}
                            >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  )
                })
              )}
              <Combobox.Option
                className={({active}) =>
                  `relative cursor-default select-none py-2 px-4 ${active ? 'bg-orange-500/50 text-white' : 'text-white'}`
                }
                onClick={() => setShowCreateNewCompany(true)}
                value={''}
              >
                {({active}) => (
                  <div className={`truncate items-center space-x-5 ${active ? 'font-medium' : 'font-normal'}`}>
                    <p>Create a new company?</p>
                  </div>
                )}
              </Combobox.Option>
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}
