import {Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle} from "../ui/Sheet";
import NewChannelForm from "./NewChannelForm";

export default function SlideoverNewChannel({open, setOpen}) {
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>New conversation</SheetTitle>
          <SheetDescription>Create a new conversation!</SheetDescription>
        </SheetHeader>
        <NewChannelForm setOpen={setOpen}/>
      </SheetContent>
    </Sheet>
  );
}
