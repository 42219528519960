import {HexColorPicker} from "react-colorful";
import {useEffect, useMemo, useState} from "react";
import { classNames } from "../utils/classes";
import {Button} from "../ui/Button";
import {Input} from "../ui/Input";
import {Popover, PopoverContent, PopoverTrigger} from "../ui/Popover";
import useSocket from '../hooks/useSocket';
import {PlusIcon} from "@heroicons/react/24/solid";
import {toast} from "react-toastify";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";

type Label = {
  id: string,
  name: string,
  color: {
    r: string,
    b: string,
    g: string,
  }
}

function hexToRgb(hexColor: string) {
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  return { r, g, b };
}

function rgbToHex(r: string, g: string, b: string) {
  return `${parseInt(r).toString(16)}${parseInt(g).toString(16)}${parseInt(b).toString(16)}`;
}

const PRESET_COLORS = ['#e3abec', '#c2dbf7', '#9fd6ff', '#9de7da', '#9df0c0', '#fff099', '#fed49a', '#d073e0', '#86baf3', '#3f82b2', '#44d8be', '#3be282', '#ffe654', '#ffb758', '#bd35bd', '#5779c1', '#5ebbff', '#00aea9', '#3cba4c', '#f5bc25', '#f99221', '#580d8c', '#001970', '#0a2399', '#0b7477', '#0b6b50', '#b67e11',];

type LabelFormProps = { label?: Label, onSave: () => void };

export default function LabelForm({label, onSave}: LabelFormProps) {
  const [color, setColor] = useState<string>();
  const [labelId, setLabelId] = useState<string>();
  const [customColor, setCustomColor] = useState<string>();
  const [labelName, setLabelName] = useState<string>(label?.name);
  const [socket] = useSocket();

  const newLabel = useMemo(() => {
    if (!customColor) return '';

    return {
      name: labelName,
      color: hexToRgb(customColor),
    }
  }, [customColor]);

  useEffect(() => {
    if (!label) return;

    const hex = `#${rgbToHex(label.color.r, label.color.g, label.color.b)}`;

    const isPreset = PRESET_COLORS.find(p => p === hex);

    setLabelId(label.id);
    isPreset ? setColor(hex) : setCustomColor(hex);
    setLabelName(label.name);
  }, [label]);

  function updateLabel() {
    socket?.emit('channels_users_tags:edit_tag', {
      tag: {
        tagId: labelId,
        tagName: labelName,
        tagColor: hexToRgb(color || customColor)
      }
    },
    ({success, msg}) => {
      success ? toast.success(msg || 'Your label has been saved!', DEFAULT_TOAST_CONFIG) : toast.error(msg || 'The label could not be saved. Try again.', DEFAULT_TOAST_CONFIG)
      onSave();
    });
  }

  function createLabel() {
    socket?.emit('channels_users_tags:create_tag', {
      tag: {
        tagName: labelName,
        tagColor: hexToRgb(color || customColor)
      }
    },
    ({success, msg}) => {
      console.log('Created label');
      success ? toast.success(msg || 'Your label has been saved!', DEFAULT_TOAST_CONFIG) : toast.error(msg || 'The label could not be saved. Try again.', DEFAULT_TOAST_CONFIG)
      onSave();
    });
  }

  return (
    <div>
      <div className='grid grid-cols-10 gap-x-12 gap-y-5 py-5'>
        <label htmlFor='label_name' className='col-span-3 whitespace-nowrap'>Label name</label>
        <Input defaultValue={label?.name} onChange={({target}) => setLabelName(target.value)} className='col-span-7' name='label_name' placeholder='Your label name...'/>
        <label htmlFor='label_color' className='col-span-3 whitespace-nowrap'>Color (optional)</label>
        <div className='col-span-7 flex items-center'>
          <div className='flex gap-4 flex-wrap'>
            {
              PRESET_COLORS.map((presetColor) => {
                return (
                  <div key={presetColor} style={{backgroundColor: presetColor}} className={classNames(presetColor === color ? 'ring ring-white' : '', `h-[30.8px] hover:scale-125 w-[30.8px] transition-transform rounded`)} onClick={() => setColor(presetColor)}></div>
                );
              })
            }
            <Popover>
              <PopoverTrigger>
                <div style={{backgroundColor: customColor}}
                     className={classNames(customColor ? 'ring ring-white' : '', 'bg-white h-[30.8px] w-[30.8px] hover:scale-125 transition-transform flex items-center justify-center rounded')}>
                  <PlusIcon className='h-5 w-5 text-black'/>
                </div>
              </PopoverTrigger>
              <PopoverContent className='w-auto'>
                <HexColorPicker color={customColor} onChange={(color) => {
                  setCustomColor(color);
                  setColor(undefined);
                }}/>
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </div>
      <div className='w-full flex justify-end'>
        <Button disabled={!labelName} onClick={labelId ? updateLabel : createLabel}>
          Save
        </Button>
      </div>
    </div>
  );
}
