import {BuildingOffice2Icon} from "@heroicons/react/24/outline";
import {LinkIcon} from "@heroicons/react/24/solid";
import {useRef, useState} from "react";
import {useClickAway} from "react-use";
import useSocket from "../hooks/useSocket";

export default function CreateCompanyForm({onCreated, onCancel}) {
  const createCompanyModalRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [companyData, setCompanyData] = useState({
    name: null,
    bio: null,
    cashtag: null,
  });
  const editCompanyAvatarFileInput = useRef(null);
  const [companyAvatar, setCompanyAvatar] = useState(null);
  const [companyAvatarFile, setCompanyAvatarFile] = useState(null);
  const [socket] = useSocket();
  useClickAway(createCompanyModalRef, onCancel);

  function previewCompanyAvatar(file) {
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      setCompanyAvatarFile(file);
      setCompanyAvatar(e.target.result);
    }

    fileReader.readAsDataURL(file);
  }

  async function saveCompany(event) {
    event.preventDefault();

    if (!createCompanyModalRef.current.reportValidity()) return;

    if (!companyAvatar) {
      setErrors({
        ...errors,
        avatar: 'You must include a company image.',
      });

      return;
    }

    const {url, key} = await socket.emitWithAck('company:avatar:put', null);
    await upload(url, companyAvatarFile);

    const {success: companyCreated, msg, company} = await socket.emitWithAck('company:create', {...companyData, avatarUrl: key});

    if (!companyCreated) {
      return toast.error(msg, DEFAULT_TOAST_CONFIG);
    }

    onCreated({
      ...company,
      avatar_url: key,
    });
  }

  return (
    <form ref={createCompanyModalRef} className="bg-gray-800 w-full z-50 ring-2 ring-white shadow-md p-5" onSubmit={saveCompany}>
      <div className="col-span-full">
        <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-100">
          Photo
        </label>
        <div className="mt-2 flex items-center gap-x-3">
          {
            !companyAvatar ? (
              <BuildingOffice2Icon className="h-12 w-12 text-gray-100" aria-hidden="true" />
            ) : (
              <img alt="Proposed company avatar" src={companyAvatar} className="h-12 w-12 object-contain" />
            )
          }
          <button
            type="button"
            onClick={() => editCompanyAvatarFileInput.current.click()}
            className="rounded-md bg-gray-800 px-2.5 py-1.5 text-sm font-semibold text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-orange-500 hover:text-orange-500"
          >
            Change
          </button>
          <input onChange={(event) => {
            previewCompanyAvatar(event.target.files[0]);
          }} type="file" ref={editCompanyAvatarFileInput} className="hidden" accept="image/jpg, image/jpeg, image/png" />
        </div>
        { errors.avatar ? <div className="text-red-500 text-xs">{errors.avatar}</div> : null }
      </div>
      <br />
      <div className="flex justify-between space-x-2">
        <div className="w-full">
          <label htmlFor="company_name" className="block text-sm font-medium leading-6 text-gray-100">
            Name
          </label>
          <input
            required
            id="company_name"
            name="company_name"
            type="company_name"
            onChange={({target: {value: name}}) => setCompanyData({...companyData, name})}
            defaultValue={companyData.name}
            autoComplete={false}
            placeholder="Name of you company..."
            className="block w-full rounded-md px-1.5 py-1.5 bg-gray-800 text-gray-100 placeholder:text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="w-full">
          <label htmlFor="cashtag" className="block text-sm font-medium leading-6 text-gray-100">
            Cashtag
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-600 sm:max-w-md">
            <span className="flex select-none items-center pl-3 text-gray-100 sm:text-sm">$</span>
            <input
              required
              type="text"
              name="cashtag"
              id="cashtag"
              autoComplete="cashtag"
              onChange={({target: {value: cashtag}}) => setCompanyData({...companyData, cashtag})}
              className="block flex-1 bg-transparent py-1.5 pl-1 text-gray-100 placeholder:text-gray-600 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="reinsuranceforyou"
            />
          </div>
        </div>
      </div>
      <br />
      <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-100">
        Website
      </label>
      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-600 w-full">
        <span className="flex select-none items-center px-2 text-gray-100 sm:text-sm">
          <LinkIcon className="h-4 w-4" />
        </span>
        <input
          type="url"
          name="website"
          id="website"
          autoComplete="website"
          onChange={({target: {value: website}}) => setCompanyData({...companyData, website})}
          className="block flex-1 bg-transparent py-1.5 pl-1 text-gray-100 placeholder:text-gray-600 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder="https://my-reinsurance-company-website.com"
        />
      </div>
      <br />
      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-100">
        Bio
      </label>
      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-600">
        <textarea
          required
          placeholder="Give a short description of your company!"
          minLength="10"
          onChange={({target: {value: bio}}) => setCompanyData({...companyData, bio})}
          className="bg-transparent w-full outline-none border-none">
        </textarea>
      </div>
      <br />
      <div className="flex justify-between">
        <button onClick={onCancel} type="button" className="text-sm font-semibold leading-6 text-gray-100">
          Cancel
        </button>
        <button
          onClick={saveCompany}
          className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
        >
          Create
        </button>
      </div>
    </form>
  );
}
