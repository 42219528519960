import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {calculateCompletion} from "./utils";

export default function useUserProfileCompletionCalculation() {
  const {user} = useSelector(state => state.user);
  const [matchingProfile, setMatchingProfile] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!user) return;

    fetch('/api/profiles/matches', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
    .then(resp => resp.json())
    .then(({success, profile}) => {
      if (!success) return;

      setMatchingProfile({profile});
    }).finally(() => setLoaded(true));
  }, [user]);

  const calculation = useMemo(() => calculateCompletion(user, matchingProfile), [user, matchingProfile]);

  if (!loaded) return [false, {}];

  return [loaded, calculation, matchingProfile];
}
