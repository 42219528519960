import {createSlice} from "@reduxjs/toolkit";

const initialState = {tagsById: {}, tags: [], labels: []};

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTags(state, action) {
      return action.payload.reduce((acc, tag) => {
        acc[tag.id] = tag;
        return acc;
      }, {});
    },
    setLabels(state, action) {
      state.labels = action.payload;
    },
    updateTag(state, action) {
      state[action.payload.tag.id] = action.payload.tag;
    },
    addTag(state, action) {
      state[action.payload.tag.id] = action.payload.tag;
    },
  },
});

export const {
  setLabels,
  setTags,
  updateTag,
  addTag,
} = tagsSlice.actions;

export default tagsSlice.reducer;
