import {ArrowLeftIcon, CheckCircleIcon} from "@heroicons/react/24/outline";
import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {Button} from "../ui/Button";
import { Link } from "wouter";
import USER_SETUP_STEPS from "./steps";
import useUserProfileCompletionCalculation from "./useUserProfileCompletionCalculation";

export default function UserSetup() {
  const {user} = useSelector(state => state.user);
  const [propsBag, setPropsBag] = useState({});
  const [step, setStep] = useState();
  const [loaded, {firstIncompleteStep, nextIncompleteStep, canGoBackTo, percentage}] = useUserProfileCompletionCalculation();

  useEffect(() => {
    if (!loaded) return;

    setStep(firstIncompleteStep);
  }, [loaded, firstIncompleteStep]);

  function nextStep(props = {}) {
    return () => {
      setPropsBag({...propsBag, ...props});
      setStep(nextIncompleteStep);
    }
  }

  function goBack() {
    if (step === 0 || !canGoBack) return;

    setStep(canGoBackTo);
  }

  const canGoBack = canGoBackTo > -1;
  const Component = useMemo(() => user && USER_SETUP_STEPS[step]?.component, [step, user, USER_SETUP_STEPS]);

  return (
    <div className='fixed bg-gray-800 z-50 top-0 left-0 h-full w-full flex flex-col overflow-y-auto'>
      {
        loaded && Component ? (
          <>
            {
              percentage < 100 ? (
                <>
                  <div className='sticky top-0 w-full bg-gray-800 font-semibold p-5 px-5'>Strengthen your account</div>
                  <div className='grow p-5'>
                    <Component next={nextStep} {...propsBag} />
                  </div>
                  <div className='sticky bottom-0 w-full'>
                    <div className='h-1.5 w-full bg-gray-900'>
                      <div className={`bg-white ${percentage === 100 ? 'rounded-none' : 'rounded-full rounded-l-none'} h-full transition-all`} style={{width: `${percentage}%`}}></div>
                    </div>
                    <div className='flex items-center justify-between bg-gray-600 p-5'>
                      {canGoBack ? <Button onClick={goBack} variant='ghost'>Back</Button> : <span></span> }
                      <Link to="/" asChild>
                        <Button variant='ghost'>Save & exit</Button>
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <div className='absolute z-50 bg-gray-800 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                  <div className='flex flex-col items-center justify-center space-y-5'>
                    <div><CheckCircleIcon className='h-20' /></div>
                    <span className='font-semibold text-xl'>You're all done!</span>
                    <Link to='/' className='flex items-center'>
                      <ArrowLeftIcon className='h-5 mr-2' />
                      <span>Back to fuse</span>
                    </Link>
                  </div>
                </div>
              )
            }
          </>
        ) : (
          <div className='absolute z-50 bg-gray-800 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>Loading...</div>
        )
      }
    </div>
  )
}
